/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const PowerOff = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M18.75 2.53594C21.7031 4.64531 23.625 8.09531 23.625 12C23.625 18.4125 18.4312 23.6109 12.0234 23.625C5.625 23.6391 0.384376 18.4219 0.375001 12.0187C0.370313 8.11406 2.29219 4.65469 5.24063 2.54063C5.78906 2.15156 6.55313 2.31563 6.88125 2.90156L7.62188 4.21875C7.89844 4.71094 7.76719 5.33437 7.3125 5.67188C5.36719 7.11562 4.125 9.40312 4.125 11.9953C4.12031 16.3219 7.61719 19.875 12 19.875C16.2937 19.875 19.9031 16.3969 19.875 11.9484C19.8609 9.52031 18.7172 7.17656 16.6828 5.66719C16.2281 5.32969 16.1016 4.70625 16.3781 4.21875L17.1187 2.90156C17.4469 2.32031 18.2062 2.14687 18.75 2.53594ZM13.875 12.375V1.125C13.875 0.501562 13.3734 0 12.75 0H11.25C10.6266 0 10.125 0.501562 10.125 1.125V12.375C10.125 12.9984 10.6266 13.5 11.25 13.5H12.75C13.3734 13.5 13.875 12.9984 13.875 12.375Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default PowerOff;
