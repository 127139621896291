/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Expand = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M1.5 8.4375V2.625C1.5 2.00156 2.00156 1.5 2.625 1.5H8.4375C8.74687 1.5 9 1.75313 9 2.0625V3.9375C9 4.24688 8.74687 4.5 8.4375 4.5H4.5V8.4375C4.5 8.74687 4.24688 9 3.9375 9H2.0625C1.75313 9 1.5 8.74687 1.5 8.4375ZM15 2.0625V3.9375C15 4.24688 15.2531 4.5 15.5625 4.5H19.5V8.4375C19.5 8.74687 19.7531 9 20.0625 9H21.9375C22.2469 9 22.5 8.74687 22.5 8.4375V2.625C22.5 2.00156 21.9984 1.5 21.375 1.5H15.5625C15.2531 1.5 15 1.75313 15 2.0625ZM21.9375 15H20.0625C19.7531 15 19.5 15.2531 19.5 15.5625V19.5H15.5625C15.2531 19.5 15 19.7531 15 20.0625V21.9375C15 22.2469 15.2531 22.5 15.5625 22.5H21.375C21.9984 22.5 22.5 21.9984 22.5 21.375V15.5625C22.5 15.2531 22.2469 15 21.9375 15ZM9 21.9375V20.0625C9 19.7531 8.74687 19.5 8.4375 19.5H4.5V15.5625C4.5 15.2531 4.24688 15 3.9375 15H2.0625C1.75313 15 1.5 15.2531 1.5 15.5625V21.375C1.5 21.9984 2.00156 22.5 2.625 22.5H8.4375C8.74687 22.5 9 22.2469 9 21.9375Z" />
  </svg>
);

export default Expand;
