/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Plug = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M18 1.5C18 1.10218 17.842 0.720644 17.5607 0.43934C17.2794 0.158035 16.8978 0 16.5 0C16.1022 0 15.7206 0.158035 15.4393 0.43934C15.158 0.720644 15 1.10218 15 1.5V6H18V1.5ZM20.25 7.5H3.75C3.55109 7.5 3.36032 7.57902 3.21967 7.71967C3.07902 7.86032 3 8.05109 3 8.25V9.75C3 9.94891 3.07902 10.1397 3.21967 10.2803C3.36032 10.421 3.55109 10.5 3.75 10.5H4.5V12C4.50026 13.7288 5.09752 15.4045 6.19078 16.7438C7.28404 18.083 8.80624 19.0036 10.5 19.35V24H13.5V19.35C15.1938 19.0036 16.716 18.083 17.8092 16.7438C18.9025 15.4045 19.4997 13.7288 19.5 12V10.5H20.25C20.4489 10.5 20.6397 10.421 20.7803 10.2803C20.921 10.1397 21 9.94891 21 9.75V8.25C21 8.05109 20.921 7.86032 20.7803 7.71967C20.6397 7.57902 20.4489 7.5 20.25 7.5ZM9 1.5C9 1.10218 8.84196 0.720644 8.56066 0.43934C8.27936 0.158035 7.89782 0 7.5 0C7.10218 0 6.72064 0.158035 6.43934 0.43934C6.15804 0.720644 6 1.10218 6 1.5V6H9V1.5Z" />
  </svg>
);

export default Plug;
