/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CintasStore = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M3.44678 11.4638C1.65954 11.4638 0.204224 10.0085 0.204224 8.22128V4.46809H6.71486V8.19575C6.68933 10.0085 5.23401 11.4638 3.44678 11.4638ZM0.970181 5.23404V8.19575C0.970181 9.57447 2.09359 10.6723 3.44678 10.6723C4.79997 10.6723 5.92337 9.54894 5.92337 8.19575V5.23404H0.970181Z" />
    <path d="M9.19147 11.4638C7.40423 11.4638 5.94891 10.0085 5.94891 8.22128V4.46809H12.4596V8.19575C12.4596 10.0085 10.9787 11.4638 9.19147 11.4638ZM6.71487 5.23404V8.19575C6.71487 9.57447 7.83828 10.6723 9.19147 10.6723C10.5447 10.6723 11.6681 9.54894 11.6681 8.19575V5.23404H6.71487Z" />
    <path d="M14.9362 11.4638C13.1489 11.4638 11.6936 10.0085 11.6936 8.22128V4.46809H18.2042V8.19575C18.2042 10.0085 16.7234 11.4638 14.9362 11.4638ZM12.4596 5.23404V8.19575C12.4596 9.57447 13.583 10.6723 14.9362 10.6723C16.3149 10.6723 17.4128 9.54894 17.4128 8.19575V5.23404H12.4596Z" />
    <path d="M20.6808 11.4638C18.8936 11.4638 17.4383 10.0085 17.4383 8.22128V4.46809H23.9489V8.19575C23.9489 10.0085 22.4681 11.4638 20.6808 11.4638ZM18.2043 5.23404V8.19575C18.2043 9.57447 19.3277 10.6723 20.6808 10.6723C22.0596 10.6723 23.1574 9.54894 23.1574 8.19575V5.23404H18.2043Z" />
    <path d="M23.9234 5.23405H0.178711V2.04256H23.9234V5.23405ZM0.9702 4.46809H23.183V2.80851H0.9702V4.46809Z" />
    <path d="M7.40426 21.8809H3.80426V13.634H7.40426V21.8809ZM4.57022 21.1149H6.6383V14.4H4.57022V21.1149Z" />
    <path d="M10.2638 21.8809H6.63831V13.634H10.2383V21.8809H10.2638ZM7.40426 21.1149H9.47235V14.4H7.40426V21.1149Z" />
    <path d="M20.6553 18.1277H11.7957V13.634H20.6553V18.1277ZM12.5617 17.3617H19.8893V14.4H12.5617V17.3617Z" />
    <path d="M22.3915 10.2894V21.1149H1.73617V10.2894L0.970215 9.52341V21.8809H23.1574V9.52341L22.3915 10.2894Z" />
  </svg>
);

export default CintasStore;
