/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CintasIncidents = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M18.3429 17.8571H11.1714V18.7143H18.3429V17.8571Z" />
    <path d="M18.3429 20.0571H11.1714V20.9143H18.3429V20.0571Z" />
    <path d="M18.3429 15.6857H11.1714V16.5429H18.3429V15.6857Z" />
    <path d="M18.3429 13.5143H11.1714V14.3714H18.3429V13.5143Z" />
    <path d="M12.5143 11.3143C12.1143 11.6571 11.6857 11.9429 11.2286 12.1714H18.3429V11.3143H12.5143Z" />
    <path d="M14.2571 9.14286C14.1143 9.42857 13.9143 9.71429 13.7429 10H18.3429V9.14286H14.2571Z" />
    <path d="M7.94285 11.5714C4.82856 11.5714 2.28571 9.02857 2.28571 5.91428C2.28571 2.8 4.82856 0.257141 7.94285 0.257141C11.0571 0.257141 13.6 2.8 13.6 5.91428C13.6 9.02857 11.0571 11.5714 7.94285 11.5714ZM7.94285 1.11428C5.28571 1.11428 3.14285 3.25714 3.14285 5.91428C3.14285 8.57143 5.28571 10.7143 7.94285 10.7143C10.6 10.7143 12.7428 8.57143 12.7428 5.91428C12.7428 3.25714 10.6 1.11428 7.94285 1.11428Z" />
    <path d="M7.77145 11.1143H6.91431V14.3143H7.77145V11.1143Z" />
    <path d="M8.97146 11.1143H8.11432V14.3143H8.97146V11.1143Z" />
    <path d="M10.1714 23.9143H5.71429V14.1714H10.1714V23.9143ZM6.57144 23.0571H9.31429V15.0286H6.57144V23.0571Z" />
    <path d="M15.0572 5.48571C15.0572 5.6 15.0572 5.71428 15.0572 5.85714C15.0572 6.02857 15.0572 6.2 15.0286 6.34285H20.8857V23.0571H11.1714V23.9143H21.7429V5.48571H15.0572Z" />
    <path d="M5.25717 5.48571C5.40002 4.17143 6.45717 3.14285 7.80002 3.05714V2.2C5.97145 2.28571 4.51431 3.71428 4.40002 5.48571H5.25717Z" />
  </svg>
);

export default CintasIncidents;
