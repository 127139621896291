/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Cube = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path
        fill-rule="evenodd"
        d="M10.9922 0.267181C11.6438 0.0328064 12.3563 0.0328064 13.0125 0.267181L22.0125 3.48281C23.2031 3.90937 24 5.03906 24 6.30937V17.6953C24 18.9609 23.2031 20.0953 22.0078 20.5219L13.0078 23.7375C12.3562 23.9719 11.6437 23.9719 10.9875 23.7375L1.9875 20.5219C0.796875 20.0906 0 18.9609 0 17.6906V6.30937C0 5.04374 0.796875 3.90937 1.99219 3.48281L10.9922 0.267181ZM3.54537 5.27533C3.02531 5.08943 2.45302 5.36032 2.26712 5.88038C2.08122 6.40043 2.35211 6.97272 2.87217 7.15862L11 10.064V21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21V10.0726L21.1316 7.16583C21.6517 6.97993 21.9226 6.40763 21.7367 5.88758C21.5508 5.36752 20.9785 5.09663 20.4584 5.28253L12.012 8.30181L3.54537 5.27533Z"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default Cube;
