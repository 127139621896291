/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CintasAntisepticsColor = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M3.21144 1.78656C1.93234 1.78656 0.891968 2.82693 0.891968 4.10603V19.8938C0.891968 21.1729 1.93234 22.2126 3.21144 22.2126H20.7884C22.0675 22.2126 23.1079 21.1729 23.1079 19.8938V4.10603C23.1079 2.82693 22.0675 1.78656 20.7884 1.78656H3.21144Z"
        fill="#7957A2"
      />
      <path
        d="M3.21138 2.67878C2.42423 2.67878 1.78413 3.31888 1.78413 4.10603V19.8942C1.78413 20.6804 2.42423 21.3205 3.21138 21.3205H20.7886C21.5757 21.3205 22.2156 20.6804 22.2156 19.8942V4.10603C22.2156 3.31888 21.5757 2.67878 20.7886 2.67878H3.21138ZM20.7886 23.1048H3.21138C1.44052 23.1048 6.10352e-05 21.6647 6.10352e-05 19.8942V4.10603C6.10352e-05 2.33516 1.44052 0.894526 3.21138 0.894526H20.7886C22.5589 0.894526 23.9999 2.33516 23.9999 4.10603V19.8942C23.9999 21.6647 22.5589 23.1048 20.7886 23.1048Z"
        fill="#818A90"
      />
      <path
        d="M14.7079 11.8151C14.7009 11.7984 14.6921 11.7843 14.6859 11.7658C14.6815 11.7499 14.6736 11.7332 14.6648 11.7174C14.552 11.4724 14.3221 11.1149 14.3221 11.1149V11.1103L13.4385 9.81104L12.0307 7.74094L9.70772 11.1501C9.55352 11.3756 9.36778 11.7376 9.33697 11.7957C9.16425 12.1949 9.07092 12.6397 9.07092 13.1093C9.07092 14.8649 10.3852 16.285 12.0087 16.285C13.1548 16.285 14.1468 15.5749 14.6268 14.5398C15.1492 13.3119 14.8681 12.2502 14.7079 11.8151Z"
        fill="#818A90"
      />
      <path
        d="M10.0483 12.097C9.91788 12.4124 9.85188 12.7523 9.85188 13.1092C9.85188 14.4545 10.8191 15.5476 12.0093 15.5476C12.81 15.5476 13.5385 15.0482 13.9129 14.2438C14.3172 13.2898 14.1048 12.4353 13.9816 12.0847C13.971 12.0618 13.9604 12.0407 13.9506 12.016C13.9506 12.0151 13.9499 12.0134 13.9499 12.0116C13.8749 11.8495 13.7112 11.5853 13.6538 11.4971C13.6477 11.4857 13.6415 11.4751 13.6355 11.4644L12.0313 9.10372L10.3636 11.5482C10.2518 11.712 10.1029 11.9965 10.0527 12.0891C10.0527 12.0908 10.0508 12.0935 10.0483 12.097ZM12.0093 17.0222C9.95932 17.0222 8.29077 15.2665 8.29077 13.1092C8.29077 12.5534 8.39914 12.0178 8.6133 11.5191C8.62118 11.4989 8.6309 11.4804 8.64135 11.461C8.72074 11.3095 8.89255 10.9826 9.05097 10.751L11.3758 7.34276C11.5193 7.13208 11.7661 7.00446 12.0322 7.00446C12.298 7.00446 12.5439 7.13208 12.6884 7.34349L14.9778 10.7121C14.9928 10.7343 15.007 10.7572 15.0183 10.78C15.0906 10.8927 15.2624 11.1729 15.3707 11.4028C15.3858 11.4327 15.4034 11.4698 15.4192 11.5147L15.4298 11.535C15.6667 12.1781 15.9522 13.4025 15.3506 14.8138C14.7163 16.1837 13.4389 17.0222 12.0093 17.0222Z"
        fill="#818A90"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CintasAntisepticsColor;
