/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const ArrowRight = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M10.438 20.8641L11.4731 21.9047C11.9114 22.3454 12.6201 22.3454 13.0538 21.9047L22.1181 12.7969C22.5564 12.3563 22.5564 11.6438 22.1181 11.2079L13.0538 2.09536C12.6155 1.65474 11.9067 1.65474 11.4731 2.09536L10.438 3.13599C9.99504 3.5813 10.0044 4.30786 10.4566 4.7438L16.0752 10.125H2.6746C2.05447 10.125 1.55556 10.6266 1.55556 11.25V12.75C1.55556 13.3735 2.05447 13.875 2.6746 13.875H16.0752L10.4566 19.2563C9.9997 19.6922 9.99038 20.4188 10.438 20.8641Z" />
  </svg>
);

export default ArrowRight;
