/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Battery = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M20 8.00166V16.0017H2.66667V8.00166H20ZM21.3333 14.0017H22.6667V10.0017H21.3333V7.00166C21.3333 6.90444 21.3021 6.82457 21.2396 6.76207C21.1771 6.69957 21.0972 6.66832 21 6.66832H1.66667C1.56944 6.66832 1.48958 6.69957 1.42708 6.76207C1.36458 6.82457 1.33333 6.90444 1.33333 7.00166V17.0017C1.33333 17.0989 1.36458 17.1787 1.42708 17.2412C1.48958 17.3037 1.56944 17.335 1.66667 17.335H21C21.0972 17.335 21.1771 17.3037 21.2396 17.2412C21.3021 17.1787 21.3333 17.0989 21.3333 17.0017V14.0017ZM24 10.0017V14.0017C24 14.3697 23.8698 14.6839 23.6094 14.9444C23.349 15.2048 23.0347 15.335 22.6667 15.335V17.0017C22.6667 17.46 22.5035 17.8524 22.1771 18.1787C21.8507 18.5051 21.4583 18.6683 21 18.6683H1.66667C1.20833 18.6683 0.815972 18.5051 0.489583 18.1787C0.163194 17.8524 0 17.46 0 17.0017V7.00166C0 6.54332 0.163194 6.15096 0.489583 5.82457C0.815972 5.49819 1.20833 5.33499 1.66667 5.33499H21C21.4583 5.33499 21.8507 5.49819 22.1771 5.82457C22.5035 6.15096 22.6667 6.54332 22.6667 7.00166V8.66832C23.0347 8.66832 23.349 8.79853 23.6094 9.05895C23.8698 9.31937 24 9.6336 24 10.0017Z" />
  </svg>
);

export default Battery;
