/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const EllipHorizontal = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M15.375 12C15.375 13.8656 13.8656 15.375 12 15.375C10.1344 15.375 8.625 13.8656 8.625 12C8.625 10.1344 10.1344 8.625 12 8.625C13.8656 8.625 15.375 10.1344 15.375 12ZM20.25 8.625C18.3844 8.625 16.875 10.1344 16.875 12C16.875 13.8656 18.3844 15.375 20.25 15.375C22.1156 15.375 23.625 13.8656 23.625 12C23.625 10.1344 22.1156 8.625 20.25 8.625ZM3.75 8.625C1.88438 8.625 0.375 10.1344 0.375 12C0.375 13.8656 1.88438 15.375 3.75 15.375C5.61562 15.375 7.125 13.8656 7.125 12C7.125 10.1344 5.61562 8.625 3.75 8.625Z" />
  </svg>
);

export default EllipHorizontal;
