/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Check = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M8.15147 20.597L0.351472 12.797C-0.117137 12.3284 -0.117137 11.5686 0.351472 11.1L2.04849 9.40291C2.5171 8.93425 3.27694 8.93425 3.74555 9.40291L9 14.6573L20.2545 3.40291C20.7231 2.9343 21.4829 2.9343 21.9515 3.40291L23.6485 5.09997C24.1171 5.56858 24.1171 6.32838 23.6485 6.79703L9.84854 20.5971C9.37988 21.0657 8.62008 21.0657 8.15147 20.597Z" />
  </svg>
);

export default Check;
