/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const ZoomIn = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M14.25 9V10.5C14.25 10.8094 13.9969 11.0625 13.6875 11.0625H11.0625V13.6875C11.0625 13.9969 10.8094 14.25 10.5 14.25H9C8.69063 14.25 8.4375 13.9969 8.4375 13.6875V11.0625H5.8125C5.50313 11.0625 5.25 10.8094 5.25 10.5V9C5.25 8.69063 5.50313 8.4375 5.8125 8.4375H8.4375V5.8125C8.4375 5.50313 8.69063 5.25 9 5.25H10.5C10.8094 5.25 11.0625 5.50313 11.0625 5.8125V8.4375H13.6875C13.9969 8.4375 14.25 8.69063 14.25 9ZM23.6719 22.3453L22.3453 23.6719C21.9047 24.1125 21.1922 24.1125 20.7562 23.6719L16.0781 18.9984C15.8672 18.7875 15.75 18.5016 15.75 18.2016V17.4375C14.0953 18.7313 12.0141 19.5 9.75 19.5C4.36406 19.5 0 15.1359 0 9.75C0 4.36406 4.36406 0 9.75 0C15.1359 0 19.5 4.36406 19.5 9.75C19.5 12.0141 18.7313 14.0953 17.4375 15.75H18.2016C18.5016 15.75 18.7875 15.8672 18.9984 16.0781L23.6719 20.7516C24.1078 21.1922 24.1078 21.9047 23.6719 22.3453ZM16.125 9.75C16.125 6.225 13.275 3.375 9.75 3.375C6.225 3.375 3.375 6.225 3.375 9.75C3.375 13.275 6.225 16.125 9.75 16.125C13.275 16.125 16.125 13.275 16.125 9.75Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default ZoomIn;
