/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Lock = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M20.25 10.5H19.125V7.125C19.125 3.19687 15.9281 0 12 0C8.07187 0 4.875 3.19687 4.875 7.125V10.5H3.75C2.50781 10.5 1.5 11.5078 1.5 12.75V21.75C1.5 22.9922 2.50781 24 3.75 24H20.25C21.4922 24 22.5 22.9922 22.5 21.75V12.75C22.5 11.5078 21.4922 10.5 20.25 10.5ZM15.375 10.5H8.625V7.125C8.625 5.26406 10.1391 3.75 12 3.75C13.8609 3.75 15.375 5.26406 15.375 7.125V10.5Z" />
  </svg>
);

export default Lock;
