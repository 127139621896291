/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const ArrowLeft = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M13.562 20.8641L12.5269 21.9047C12.0886 22.3454 11.3799 22.3454 10.9462 21.9047L1.88194 12.7969C1.44365 12.3563 1.44365 11.6438 1.88194 11.2079L10.9462 2.09536C11.3845 1.65474 12.0933 1.65474 12.5269 2.09536L13.562 3.13599C14.005 3.5813 13.9956 4.30786 13.5434 4.7438L7.9248 10.125H21.3254C21.9455 10.125 22.4444 10.6266 22.4444 11.25V12.75C22.4444 13.3735 21.9455 13.875 21.3254 13.875H7.9248L13.5434 19.2563C14.0003 19.6922 14.0096 20.4188 13.562 20.8641Z" />
  </svg>
);

export default ArrowLeft;
