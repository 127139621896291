/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const ExclamationAltCircle = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M0.375 12C0.375 18.4217 5.58014 23.625 12 23.625C18.4199 23.625 23.625 18.4217 23.625 12C23.625 5.58202 18.4199 0.375 12 0.375C5.58014 0.375 0.375 5.58202 0.375 12ZM12 14.3438C13.1909 14.3438 14.1562 15.3091 14.1562 16.5C14.1562 17.6909 13.1909 18.6562 12 18.6562C10.8091 18.6562 9.84375 17.6909 9.84375 16.5C9.84375 15.3091 10.8091 14.3438 12 14.3438ZM14.0472 6.59316L13.6995 12.9682C13.6832 13.2665 13.4365 13.5 13.1378 13.5H10.8622C10.5635 13.5 10.3168 13.2665 10.3005 12.9682L9.95283 6.59316C9.93525 6.27094 10.1918 6 10.5145 6H13.4856C13.8083 6 14.0648 6.27094 14.0472 6.59316Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" transform="matrix(-1 0 0 1 24 0)" />
      </clipPath>
    </defs>
  </svg>
);

export default ExclamationAltCircle;
