/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const StarOutline = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M22.0042 8.47919L15.9167 7.59169L13.1958 2.07502C12.7083 1.09169 11.2958 1.07919 10.8042 2.07502L8.08334 7.59169L1.99584 8.47919C0.904176 8.63752 0.466676 9.98335 1.25834 10.7542L5.66251 15.0459L4.62084 21.1084C4.43334 22.2042 5.58751 23.025 6.55418 22.5125L12 19.65L17.4458 22.5125C18.4125 23.0209 19.5667 22.2042 19.3792 21.1084L18.3375 15.0459L22.7417 10.7542C23.5333 9.98335 23.0958 8.63752 22.0042 8.47919ZM16.1917 14.3459L17.1792 20.1125L12 17.3917L6.82084 20.1125L7.80834 14.3459L3.61668 10.2625L9.40834 9.42085L12 4.17085L14.5917 9.42085L20.3833 10.2625L16.1917 14.3459Z" />
  </svg>
);

export default StarOutline;
