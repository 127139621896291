/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const SmartShelf = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M5.27796 12.4343L5.5492 4.44232L3.75468 3.92062L3.52512 11.9544L5.27796 12.4343Z" />
    <path d="M20.3064 20.7211V3.80945L12.2454 6.4416L10.6003 5.78356V13.5814L11.8177 14.1407L12.0809 23.1888L20.3064 20.7211Z" />
    <path
      d="M3.5 4.44152C3.5 3.79587 3.91315 3.22266 4.52566 3.01849L11.2094 0.790569C11.7226 0.619513 12.2774 0.619514 12.7906 0.790569L19.4743 3.01849C20.0869 3.22266 20.5 3.79587 20.5 4.44152V19.5585C20.5 20.2041 20.0869 20.7773 19.4743 20.9815L12.7906 23.2094C12.2774 23.3805 11.7226 23.3805 11.2094 23.2094L4.52566 20.9815C3.91314 20.7773 3.5 20.2041 3.5 19.5585V4.44152Z"
      stroke="black"
    />
    <rect x="11.5" y="13.6562" width="1" height="9.85358" rx="0.5" />
    <rect x="10" y="5.43582" width="1" height="8.69434" rx="0.5" />
    <rect x="11.5" y="1.99763" width="1" height="4.24377" rx="0.5" />
    <rect
      width="1"
      height="8.67529"
      rx="0.5"
      transform="matrix(-0.317305 -0.948324 -0.948324 0.317305 13.227 17.3499)"
    />
    <rect x="5.00003" y="3.79865" width="1" height="9.20142" rx="0.5" />
    <rect
      x="11.4009"
      y="6.0943"
      width="9.1466"
      height="1"
      rx="0.5"
      transform="rotate(-18.3562 11.4009 6.0943)"
    />
    <rect
      width="2.57312"
      height="1"
      rx="0.5"
      transform="matrix(-0.949117 -0.314924 -0.314924 0.949117 12.6242 6.1026)"
    />
    <rect
      width="2.57312"
      height="1"
      rx="0.5"
      transform="matrix(-0.949117 -0.314924 -0.314924 0.949117 12.6242 13.8103)"
    />
    <rect
      width="2.33445"
      height="1"
      rx="0.5"
      transform="matrix(-0.949117 -0.314924 -0.314924 0.949117 6.13348 3.94894)"
    />
    <rect
      width="2.69057"
      height="1"
      rx="0.5"
      transform="matrix(-0.949117 -0.314924 -0.314924 0.949117 6.09668 12.192)"
    />
  </svg>
);

export default SmartShelf;
