/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Sidebar = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M0 4.5C0 2.84297 1.34297 1.5 3 1.5H21C22.6547 1.5 24 2.84297 24 4.5V19.5C24 21.1547 22.6547 22.5 21 22.5H3C1.34297 22.5 0 21.1547 0 19.5V4.5ZM10.5 19.5H21V4.5H10.5V19.5ZM4.125 4.5C3.50391 4.5 3 5.00156 3 5.625C3 6.24844 3.50391 6.75 4.125 6.75H6.375C6.99844 6.75 7.5 6.24844 7.5 5.625C7.5 5.00156 6.99844 4.5 6.375 4.5H4.125ZM4.125 11.25H6.375C6.99844 11.25 7.5 10.7484 7.5 10.125C7.5 9.50156 6.99844 9 6.375 9H4.125C3.50391 9 3 9.50156 3 10.125C3 10.7484 3.50391 11.25 4.125 11.25ZM4.125 13.5C3.50391 13.5 3 14.0016 3 14.625C3 15.2484 3.50391 15.75 4.125 15.75H6.375C6.99844 15.75 7.5 15.2484 7.5 14.625C7.5 14.0016 6.99844 13.5 6.375 13.5H4.125Z" />
  </svg>
);

export default Sidebar;
