/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CircleChecked = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M12 0.375C5.57967 0.375 0.375 5.57967 0.375 12C0.375 18.4203 5.57967 23.625 12 23.625C18.4203 23.625 23.625 18.4203 23.625 12C23.625 5.57967 18.4203 0.375 12 0.375ZM12 2.625C17.1812 2.625 21.375 6.81802 21.375 12C21.375 17.1812 17.182 21.375 12 21.375C6.81881 21.375 2.625 17.182 2.625 12C2.625 6.81881 6.81802 2.625 12 2.625ZM18.5721 8.73127L17.5157 7.66636C17.2969 7.44581 16.9408 7.44436 16.7202 7.66317L10.0943 14.2358L7.29159 11.4103C7.07283 11.1898 6.71667 11.1883 6.49613 11.4071L5.43117 12.4635C5.21062 12.6822 5.20917 13.0384 5.42798 13.259L9.68334 17.5488C9.90211 17.7693 10.2583 17.7708 10.4788 17.552L18.5689 9.52678C18.7894 9.30797 18.7908 8.95181 18.5721 8.73127Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default CircleChecked;
