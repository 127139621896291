/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Edit = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M20.6002 7.08324L16.9144 3.39652C16.7591 3.2412 16.5058 3.2412 16.3505 3.39652L7.42602 12.3231L7.00513 16.1161C6.94793 16.6229 7.37699 17.0521 7.88369 16.9949L11.6758 16.5739L20.6002 7.64728C20.7555 7.49196 20.7555 7.23855 20.6002 7.08324ZM21.5401 0.465949L23.5342 2.46054C24.1553 3.0818 24.1553 4.09136 23.5342 4.71671L22.0876 6.1636C21.9323 6.31892 21.679 6.31892 21.5237 6.1636L17.8379 2.47689C17.6826 2.32157 17.6826 2.06816 17.8379 1.91284L19.2844 0.465949C19.9096 -0.155316 20.9189 -0.155316 21.5401 0.465949ZM4.5 3C2.84297 3 1.5 4.34297 1.5 6V19.5C1.5 21.1547 2.84297 22.5 4.5 22.5H18C19.6547 22.5 21 21.1547 21 19.5V15C21 14.1703 20.3297 13.5 19.5 13.5C18.6703 13.5 18 14.1703 18 15V19.5H4.5V6H9C9.82969 6 10.5 5.32969 10.5 4.5C10.5 3.67172 9.82969 3 9 3H4.5Z"
    />
  </svg>
);

export default Edit;
