/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Plaster = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M0 8.4V15.6C0 16.9238 1.07625 18 2.4 18H6V6H2.4C1.07625 6 0 7.07625 0 8.4ZM21.6 6H18V18H21.6C22.9238 18 24 16.9238 24 15.6V8.4C24 7.07625 22.9238 6 21.6 6ZM7.2 18H16.8V6H7.2V18ZM13.8 9.3C14.2988 9.3 14.7 9.70125 14.7 10.2C14.7 10.6988 14.2988 11.1 13.8 11.1C13.3013 11.1 12.9 10.6988 12.9 10.2C12.9 9.70125 13.3013 9.3 13.8 9.3ZM13.8 12.9C14.2988 12.9 14.7 13.3013 14.7 13.8C14.7 14.2987 14.2988 14.7 13.8 14.7C13.3013 14.7 12.9 14.2987 12.9 13.8C12.9 13.3013 13.3013 12.9 13.8 12.9ZM10.2 9.3C10.6988 9.3 11.1 9.70125 11.1 10.2C11.1 10.6988 10.6988 11.1 10.2 11.1C9.70125 11.1 9.3 10.6988 9.3 10.2C9.3 9.70125 9.70125 9.3 10.2 9.3ZM10.2 12.9C10.6988 12.9 11.1 13.3013 11.1 13.8C11.1 14.2987 10.6988 14.7 10.2 14.7C9.70125 14.7 9.3 14.2987 9.3 13.8C9.3 13.3013 9.70125 12.9 10.2 12.9Z" />
  </svg>
);

export default Plaster;
