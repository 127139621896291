/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const ArrowAltRight = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M16.216 13.875H2.06249C1.75185 13.875 1.49999 13.6232 1.49999 13.3125V10.6875C1.49999 10.3769 1.75185 10.125 2.06249 10.125H16.216V7.96602C16.216 6.96373 17.4277 6.4618 18.1365 7.1705L22.1705 11.2045C22.6099 11.6439 22.6099 12.3562 22.1705 12.7955L18.1365 16.8295C17.4278 17.5382 16.216 17.0363 16.216 16.034V13.875Z" />
  </svg>
);

export default ArrowAltRight;
