/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const EyeSlash = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M1.45491 2.59148C1.06492 2.28399 0.498677 2.35524 0.191183 2.74523C-0.116312 3.13522 -0.0450631 3.70146 0.34493 4.00896L22.5445 21.4087C22.9345 21.7161 23.5008 21.6449 23.8083 21.2549C24.1158 20.8649 24.0445 20.2987 23.6545 19.9912L19.7096 16.9012C21.1946 15.3788 22.1995 13.6725 22.7058 12.4613C22.8295 12.1651 22.8295 11.8351 22.7058 11.5388C22.147 10.2001 20.9733 8.25013 19.2183 6.62266C17.4559 4.98019 15.0297 3.60021 11.9997 3.60021C9.44227 3.60021 7.31231 4.58645 5.65109 5.88017L1.45491 2.59148ZM8.36604 8.00639C9.32227 7.13265 10.601 6.60016 11.9997 6.60016C14.9809 6.60016 17.3996 9.01887 17.3996 12.0001C17.3996 12.9338 17.1634 13.8113 16.7471 14.5763L15.2997 13.4438C15.6147 12.7201 15.6972 11.8913 15.4797 11.0701C15.0634 9.51386 13.6872 8.46763 12.1572 8.40388C11.9397 8.39638 11.8122 8.63263 11.8797 8.84262C11.9585 9.08262 12.0035 9.33761 12.0035 9.60386C12.0035 9.98635 11.9135 10.3463 11.756 10.6651L8.36979 8.01014L8.36604 8.00639ZM13.9872 17.0212C13.3722 17.265 12.701 17.4 11.9997 17.4C9.01853 17.4 6.59982 14.9813 6.59982 12.0001C6.59982 11.7413 6.61857 11.4901 6.65232 11.2426L3.11613 8.45638C2.26115 9.57011 1.64991 10.6801 1.29366 11.5388C1.16992 11.8351 1.16992 12.1651 1.29366 12.4613C1.8524 13.8 3.02613 15.75 4.7811 17.3775C6.54357 19.0199 8.96978 20.3999 11.9997 20.3999C13.7922 20.3999 15.3709 19.9162 16.7321 19.1812L13.9872 17.0212Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default EyeSlash;
