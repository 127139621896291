/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CaretUpDown = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M5.96714 13H18.0281C18.8625 13 19.2796 14.0078 18.689 14.5984L12.6609 20.6312C12.2953 20.9969 11.7 20.9969 11.3343 20.6312L5.30621 14.5984C4.71558 14.0078 5.13277 13 5.96714 13Z" />
    <path d="M5.96714 10.9055H18.0281C18.8625 10.9055 19.2796 9.89764 18.689 9.30702L12.6609 3.27421C12.2953 2.90858 11.7 2.90858 11.3343 3.27421L5.30621 9.30702C4.71558 9.89764 5.13277 10.9055 5.96714 10.9055Z" />
  </svg>
);

export default CaretUpDown;
