/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Square = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M1120 128H288C244 128 206.333 143.667 175 175C143.667 206.333 128 244 128 288V1120C128 1164 143.667 1201.67 175 1233C206.333 1264.33 244 1280 288 1280H1120C1164 1280 1201.67 1264.33 1233 1233C1264.33 1201.67 1280 1164 1280 1120V288C1280 244 1264.33 206.333 1233 175C1201.67 143.667 1164 128 1120 128ZM1408 288V1120C1408 1199.33 1379.83 1267.17 1323.5 1323.5C1267.17 1379.83 1199.33 1408 1120 1408H288C208.667 1408 140.833 1379.83 84.5 1323.5C28.1667 1267.17 0 1199.33 0 1120V288C0 208.667 28.1667 140.833 84.5 84.5C140.833 28.1667 208.667 0 288 0H1120C1199.33 0 1267.17 28.1667 1323.5 84.5C1379.83 140.833 1408 208.667 1408 288Z" />
      <path d="M20.25 1.5H3.75C2.50781 1.5 1.5 2.50781 1.5 3.75V20.25C1.5 21.4922 2.50781 22.5 3.75 22.5H20.25C21.4922 22.5 22.5 21.4922 22.5 20.25V3.75C22.5 2.50781 21.4922 1.5 20.25 1.5ZM19.9688 20.25H4.03125C3.87656 20.25 3.75 20.1234 3.75 19.9688V4.03125C3.75 3.87656 3.87656 3.75 4.03125 3.75H19.9688C20.1234 3.75 20.25 3.87656 20.25 4.03125V19.9688C20.25 20.1234 20.1234 20.25 19.9688 20.25Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default Square;
