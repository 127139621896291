/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CalendarCheck = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M7.44346 0C8.28331 0 8.96183 0.670312 8.96183 1.5V3H15.0353V1.5C15.0353 0.670312 15.7138 0 16.5537 0C17.3935 0 18.072 0.670312 18.072 1.5V3H20.3496C21.607 3 22.6271 4.00781 22.6271 5.25V7.5H1.37V5.25C1.37 4.00781 2.39015 3 3.64755 3H5.9251V1.5C5.9251 0.670312 6.60362 0 7.44346 0ZM1.37 9H22.6271V21.75C22.6271 22.9922 21.607 24 20.3496 24H3.64755C2.39015 24 1.37 22.9922 1.37 21.75V9ZM16.9807 14.2969C17.4267 13.8562 17.4267 13.1438 16.9807 12.7078C16.5347 12.2719 15.8135 12.2672 15.3722 12.7078L10.8645 17.1609L8.63443 14.9578C8.18841 14.5172 7.46719 14.5172 7.02591 14.9578C6.58464 15.3984 6.57989 16.1109 7.02591 16.5469L10.0626 19.5469C10.5087 19.9875 11.2299 19.9875 11.6712 19.5469L16.9807 14.2969Z" />
  </svg>
);

export default CalendarCheck;
