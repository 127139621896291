/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CintasProductivityColor = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.03328 21.5632C8.03018 21.5553 8.02624 21.5491 8.02314 21.5406C8.02169 21.5328 8.01858 21.5251 8.01382 21.5172C7.96392 21.4029 7.86287 21.2356 7.86287 21.2356V21.2331L7.47214 20.6254L6.85034 19.6566L5.82372 21.252C5.75539 21.3578 5.67277 21.5266 5.65952 21.5539C5.58332 21.7407 5.54211 21.9492 5.54211 22.1687C5.54211 22.9905 6.12272 23.6552 6.84019 23.6552C7.34687 23.6552 7.78502 23.3228 7.99746 22.8381C8.22792 22.2637 8.1041 21.7672 8.03328 21.5632Z"
      fill="#818A90"
    />
    <path
      d="M6.84041 24C5.93451 24 5.19757 23.179 5.19757 22.1687C5.19757 21.908 5.24499 21.6581 5.34003 21.4248C5.34314 21.4154 5.34686 21.4061 5.35163 21.3974C5.38745 21.3266 5.46282 21.1742 5.53301 21.0651L6.56025 19.4697C6.62402 19.3709 6.73294 19.3117 6.85055 19.3117C6.96796 19.3117 7.07708 19.3717 7.14085 19.4705L8.15256 21.0473C8.1596 21.0574 8.16498 21.0676 8.17037 21.0783C8.20226 21.1314 8.27783 21.2628 8.32608 21.3703C8.33312 21.3842 8.34099 21.4014 8.34782 21.4225L8.35258 21.4326C8.45694 21.7329 8.58284 22.3064 8.31759 22.9666C8.03661 23.6078 7.47236 24 6.84041 24ZM5.97426 21.6956C5.91587 21.8426 5.88709 22.0023 5.88709 22.1687C5.88709 22.7984 6.31426 23.3105 6.84041 23.3105C7.19448 23.3105 7.51667 23.0763 7.6817 22.6994C7.8606 22.2536 7.7666 21.8536 7.71214 21.6894C7.70738 21.6792 7.70261 21.6683 7.69889 21.6575C7.69889 21.6567 7.69806 21.6558 7.69806 21.655C7.66451 21.5796 7.59225 21.4552 7.56802 21.4138C7.56492 21.4092 7.56181 21.4036 7.55953 21.3991L6.84972 20.294L6.11362 21.438C6.06371 21.515 5.99745 21.6482 5.97571 21.6917C5.97571 21.6917 5.97488 21.6933 5.97426 21.6956Z"
      fill="#818A90"
    />
    <path
      d="M6.65348 16.0328C7.58278 16.0289 8.42945 16.3792 9.07156 16.9534L22.0092 8.33197L17.0549 0.90733L1.99084 10.9376C3.10381 12.6062 4.49299 14.688 5.51712 16.2235C5.87513 16.1028 6.25571 16.0345 6.65348 16.0328Z"
      fill="#D5378F"
    />
    <path
      d="M9.07169 17.8607C8.85386 17.8607 8.6381 17.7829 8.46706 17.6296C7.9697 17.1853 7.33071 16.9401 6.66914 16.9401C6.37118 16.9416 6.08481 16.989 5.80776 17.0832C5.41931 17.2141 4.99048 17.0685 4.76334 16.7268L1.23623 11.441C1.10226 11.2409 1.05402 10.9958 1.10164 10.7599C1.14823 10.5233 1.28758 10.3164 1.48761 10.1824L16.5522 0.152319C16.9686 -0.125559 17.5314 -0.0127106 17.8091 0.403692L22.7635 7.82895C22.8975 8.02898 22.9457 8.27414 22.8989 8.50998C22.8515 8.74583 22.7121 8.95351 22.5121 9.08748L9.57444 17.7081C9.42121 17.8108 9.24603 17.8607 9.07169 17.8607ZM6.64968 15.1258C7.53301 15.1258 8.37989 15.3766 9.10357 15.8419L20.751 8.08033L16.803 2.16476L3.2495 11.1896L5.91812 15.1898C6.16018 15.1484 6.40451 15.1267 6.64968 15.1258Z"
      fill="#818A90"
    />
  </svg>
);

export default CintasProductivityColor;
