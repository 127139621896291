/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const ExclamationTriangle = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M23.7298 19.6398C24.4989 20.9681 23.5335 22.6286 21.9974 22.6286H2.00217C0.463129 22.6286 -0.497788 20.9656 0.269795 19.6398L10.2675 2.36724C11.037 1.03829 12.9642 1.0407 13.7323 2.36724L23.7298 19.6398ZM11.9999 16.0688C10.9414 16.0688 10.0833 16.9238 10.0833 17.9786C10.0833 19.0333 10.9414 19.8884 11.9999 19.8884C13.0585 19.8884 13.9166 19.0333 13.9166 17.9786C13.9166 16.9238 13.0585 16.0688 11.9999 16.0688ZM10.1802 9.20394L10.4893 14.8504C10.5038 15.1146 10.723 15.3214 10.9885 15.3214H13.0113C13.2768 15.3214 13.4961 15.1146 13.5105 14.8504L13.8196 9.20394C13.8353 8.91855 13.6072 8.67857 13.3204 8.67857H10.6794C10.3926 8.67857 10.1646 8.91855 10.1802 9.20394Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default ExclamationTriangle;
