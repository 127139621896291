/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Heart = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M21.6704 2.93441C19.1016 0.745343 15.2813 1.13909 12.9235 3.57191L12.0001 4.52347L11.0766 3.57191C8.72349 1.13909 4.89849 0.745343 2.32974 2.93441C-0.614006 5.44691 -0.768694 9.95628 1.86568 12.6797L10.936 22.0453C11.5219 22.65 12.4735 22.65 13.0594 22.0453L22.1297 12.6797C24.7688 9.95628 24.6141 5.44691 21.6704 2.93441Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default Heart;
