/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Link = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M15.3095 8.68974C18.1101 11.4933 18.0717 15.9881 15.3263 18.7486C15.3212 18.7543 15.3151 18.7604 15.3095 18.766L12.1595 21.916C9.38117 24.6943 4.86107 24.6939 2.08321 21.916C-0.695074 19.1382 -0.695074 14.6176 2.08321 11.8397L3.82255 10.1004C4.2838 9.63914 5.07814 9.94571 5.10196 10.5976C5.13233 11.4283 5.2813 12.2629 5.55618 13.0688C5.64927 13.3418 5.58275 13.6436 5.37885 13.8475L4.76539 14.461C3.45168 15.7747 3.41047 17.9138 4.71125 19.2404C6.02488 20.58 8.18403 20.588 9.50774 19.2643L12.6577 16.1147C13.9792 14.7933 13.9737 12.6574 12.6577 11.3415C12.4843 11.1683 12.3095 11.0338 12.173 10.9398C12.0764 10.8735 11.9967 10.7855 11.9402 10.6829C11.8836 10.5803 11.8518 10.4659 11.8474 10.3489C11.8288 9.85355 12.0043 9.34313 12.3957 8.95172L13.3826 7.96477C13.6414 7.70597 14.0474 7.67419 14.3475 7.88363C14.6912 8.12361 15.0131 8.39335 15.3095 8.68974ZM21.9158 2.08308C19.1379 -0.694824 14.6178 -0.695199 11.8395 2.08308L8.68954 5.23308C8.68391 5.23871 8.67782 5.2448 8.67266 5.25043C5.92738 8.01099 5.88889 12.5058 8.68954 15.3093C8.98592 15.6057 9.3078 15.8754 9.65146 16.1154C9.95155 16.3248 10.3576 16.293 10.6163 16.0343L11.6032 15.0473C11.9946 14.6559 12.1701 14.1455 12.1516 13.6501C12.1471 13.5331 12.1153 13.4187 12.0588 13.3161C12.0022 13.2135 11.9225 13.1255 11.8259 13.0592C11.6894 12.9653 11.5147 12.8307 11.3412 12.6576C10.0253 11.3416 10.0198 9.20574 11.3412 7.88428L14.4912 4.73475C15.8149 3.41105 17.974 3.41902 19.2877 4.75866C20.5885 6.08522 20.5473 8.22432 19.2335 9.53804L18.6201 10.1515C18.4162 10.3554 18.3497 10.6573 18.4428 10.9302C18.7176 11.7361 18.8666 12.5708 18.897 13.4015C18.9208 14.0533 19.7151 14.3599 20.1764 13.8986L21.9157 12.1593C24.6941 9.38152 24.6941 4.86089 21.9158 2.08308Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default Link;
