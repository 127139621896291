/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const List = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M4.125 2.25C4.74844 2.25 5.25 2.75391 5.25 3.375V5.625C5.25 6.24844 4.74844 6.75 4.125 6.75H1.875C1.25391 6.75 0.75 6.24844 0.75 5.625V3.375C0.75 2.75391 1.25391 2.25 1.875 2.25H4.125ZM22.5 3C23.3297 3 24 3.67172 24 4.5C24 5.32969 23.3297 6 22.5 6H9C8.17031 6 7.5 5.32969 7.5 4.5C7.5 3.67172 8.17031 3 9 3H22.5ZM22.5 10.5C23.3297 10.5 24 11.1703 24 12C24 12.8297 23.3297 13.5 22.5 13.5H9C8.17031 13.5 7.5 12.8297 7.5 12C7.5 11.1703 8.17031 10.5 9 10.5H22.5ZM22.5 18C23.3297 18 24 18.6703 24 19.5C24 20.3297 23.3297 21 22.5 21H9C8.17031 21 7.5 20.3297 7.5 19.5C7.5 18.6703 8.17031 18 9 18H22.5ZM0.75 10.875C0.75 10.2516 1.25391 9.75 1.875 9.75H4.125C4.74844 9.75 5.25 10.2516 5.25 10.875V13.125C5.25 13.7484 4.74844 14.25 4.125 14.25H1.875C1.25391 14.25 0.75 13.7484 0.75 13.125V10.875ZM4.125 17.25C4.74844 17.25 5.25 17.7516 5.25 18.375V20.625C5.25 21.2484 4.74844 21.75 4.125 21.75H1.875C1.25391 21.75 0.75 21.2484 0.75 20.625V18.375C0.75 17.7516 1.25391 17.25 1.875 17.25H4.125Z" />
  </svg>
);

export default List;
