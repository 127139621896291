/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const DeviceFirstAidCabinet = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M8 7H16C16.5523 7 17 7.44772 17 8V16C17 16.5523 16.5523 17 16 17H8C7.44772 17 7 16.5523 7 16V8C7 7.44772 7.44772 7 8 7ZM15 11.625V12.375C15 12.4766 14.9629 12.5645 14.8887 12.6387C14.8145 12.7129 14.7266 12.75 14.625 12.75H12.75V14.625C12.75 14.7266 12.7129 14.8145 12.6387 14.8887C12.5645 14.9629 12.4766 15 12.375 15H11.625C11.5234 15 11.4355 14.9629 11.3613 14.8887C11.2871 14.8145 11.25 14.7266 11.25 14.625V12.75H9.375C9.27344 12.75 9.18555 12.7129 9.11133 12.6387C9.03711 12.5645 9 12.4766 9 12.375V11.625C9 11.5234 9.03711 11.4355 9.11133 11.3613C9.18555 11.2871 9.27344 11.25 9.375 11.25H11.25V9.375C11.25 9.27344 11.2871 9.18555 11.3613 9.11133C11.4355 9.03711 11.5234 9 11.625 9H12.375C12.4766 9 12.5645 9.03711 12.6387 9.11133C12.7129 9.18555 12.75 9.27344 12.75 9.375V11.25H14.625C14.7266 11.25 14.8145 11.2871 14.8887 11.3613C14.9629 11.4355 15 11.5234 15 11.625Z"
    />
    <path
      fill-rule="evenodd"
      d="M2 3C2 1.34315 3.34315 0 5 0H19C20.6569 0 22 1.34315 22 3V21C22 22.6569 20.6569 24 19 24H5C3.34315 24 2 22.6569 2 21V3ZM20 8.14396C20 7.78079 20.1969 7.44617 20.5144 7.2698L21 7V17L20.5144 16.7302C20.1969 16.5538 20 16.2192 20 15.856V8.14396ZM18.5 21C18.2239 21 18 21.2239 18 21.5C18 21.7761 18.2239 22 18.5 22C18.7761 22 19 21.7761 19 21.5C19 21.2239 18.7761 21 18.5 21ZM16 21.5C16 21.2239 16.2239 21 16.5 21C16.7761 21 17 21.2239 17 21.5C17 21.7761 16.7761 22 16.5 22C16.2239 22 16 21.7761 16 21.5ZM14.5 21C14.2239 21 14 21.2239 14 21.5C14 21.7761 14.2239 22 14.5 22C14.7761 22 15 21.7761 15 21.5C15 21.2239 14.7761 21 14.5 21ZM6 8C6 6.89543 6.89543 6 8 6H16C17.1046 6 18 6.89543 18 8V16C18 17.1046 17.1046 18 16 18H8C6.89543 18 6 17.1046 6 16V8Z"
    />
  </svg>
);

export default DeviceFirstAidCabinet;
