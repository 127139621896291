/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const ArrowDown = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M3.13592 10.438L2.0953 11.4731C1.65467 11.9114 1.65467 12.6201 2.0953 13.0538L11.2031 22.1181C11.6437 22.5564 12.3562 22.5564 12.7922 22.1181L21.9047 13.0538C22.3453 12.6155 22.3453 11.9067 21.9047 11.4731L20.864 10.438C20.4187 9.99504 19.6922 10.0044 19.2562 10.4566L13.875 16.0752L13.875 2.6746C13.875 2.05447 13.3734 1.55556 12.75 1.55556L11.25 1.55556C10.6265 1.55556 10.125 2.05447 10.125 2.6746L10.125 16.0752L4.74373 10.4566C4.3078 9.9997 3.58123 9.99038 3.13592 10.438Z" />
  </svg>
);

export default ArrowDown;
