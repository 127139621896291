/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Sensors = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M7.848 7.81559C8.268 8.23886 8.292 8.87983 7.968 9.37567C7.4677 10.1581 7.20164 11.0692 7.20164 12C7.20164 12.9308 7.4677 13.8419 7.968 14.6243C8.292 15.1202 8.256 15.7732 7.836 16.1844C7.32 16.7044 6.432 16.6682 6.024 16.0514C5.22501 14.8538 4.79882 13.4431 4.8 12C4.8 10.5367 5.232 9.18217 5.964 8.03327C6.396 7.38021 7.296 7.25928 7.848 7.81559ZM16.152 16.1844C16.668 16.7044 17.556 16.6682 17.964 16.0514C18.769 14.8538 19.1993 13.4405 19.1993 11.994C19.1993 10.5474 18.769 9.13412 17.964 7.93652C17.556 7.33184 16.668 7.28346 16.152 7.80349C15.732 8.22677 15.708 8.86774 16.02 9.36358C16.5269 10.1453 16.7978 11.0584 16.7999 11.9922C16.8021 12.926 16.5353 13.8403 16.032 14.6243C15.708 15.1202 15.744 15.7732 16.152 16.1844ZM12 9.58126C10.68 9.58126 9.6 10.6697 9.6 12C9.6 13.3303 10.68 14.4187 12 14.4187C13.32 14.4187 14.4 13.3303 14.4 12C14.4 10.6697 13.32 9.58126 12 9.58126ZM19.584 19.6432C20.088 20.1511 20.928 20.1149 21.384 19.5465C23.016 17.4784 24 14.8541 24 12C24 9.14589 23.016 6.52156 21.384 4.45354C20.94 3.88514 20.1 3.84886 19.584 4.35679C19.152 4.79217 19.104 5.46941 19.488 5.95316C20.808 7.60999 21.6 9.71429 21.6 12C21.6 14.2857 20.808 16.39 19.488 18.0468C19.104 18.5185 19.152 19.2078 19.584 19.6432ZM4.416 4.35679C3.912 3.84886 3.072 3.88514 2.616 4.45354C0.984 6.52156 0 9.14589 0 12C0 14.8541 0.984 17.4784 2.616 19.5465C3.06 20.1149 3.9 20.1511 4.416 19.6432C4.848 19.2078 4.896 18.5306 4.512 18.0468C3.192 16.39 2.4 14.2857 2.4 12C2.4 9.71429 3.192 7.60999 4.512 5.95316C4.896 5.48151 4.848 4.79217 4.416 4.35679Z" />
  </svg>
);

export default Sensors;
