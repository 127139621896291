/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const AngleDoubleLeft = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M11.9905 12.7969L18.3655 19.1719C18.8061 19.6125 19.5186 19.6125 19.9545 19.1719L21.0139 18.1125C21.4545 17.6719 21.4545 16.9594 21.0139 16.5234L16.4952 12.0047L21.0139 7.48593C21.4545 7.04531 21.4545 6.33281 21.0139 5.89687L19.9592 4.82812C19.5186 4.38749 18.8061 4.38749 18.3702 4.82812L11.9952 11.2031C11.5499 11.6438 11.5499 12.3563 11.9905 12.7969ZM2.99049 11.2031L9.36549 4.82812C9.80611 4.38749 10.5186 4.38749 10.9545 4.82812L12.0139 5.8875C12.4545 6.32812 12.4545 7.04062 12.0139 7.47656L7.49517 11.9953L12.0139 16.5141C12.4545 16.9547 12.4545 17.6672 12.0139 18.1031L10.9545 19.1625C10.5139 19.6031 9.80142 19.6031 9.36549 19.1625L2.99049 12.7875C2.54986 12.3563 2.54986 11.6438 2.99049 11.2031Z" />
  </svg>
);

export default AngleDoubleLeft;
