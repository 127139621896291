/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Question = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M12.3705 0C8.52367 0 6.07017 1.62049 4.14353 4.50056C3.89393 4.87369 3.97744 5.37692 4.33518 5.64819L6.78452 7.50537C7.15193 7.78398 7.67541 7.71769 7.96087 7.35558C9.22186 5.75619 10.0865 4.82873 12.0058 4.82873C13.4478 4.82873 15.2315 5.75682 15.2315 7.15518C15.2315 8.21231 14.3589 8.7552 12.935 9.55348C11.2745 10.4843 9.07723 11.6429 9.07723 14.5412V14.8235C9.07723 15.2913 9.4565 15.6706 9.92428 15.6706H13.8772C14.345 15.6706 14.7243 15.2913 14.7243 14.8235V14.7294C14.7243 12.7204 20.5962 12.6367 20.5962 7.2C20.5962 3.10574 16.3493 0 12.3705 0ZM11.9008 17.5059C10.1103 17.5059 8.6537 18.9625 8.6537 20.7529C8.6537 22.5433 10.1103 24 11.9008 24C13.6912 24 15.1478 22.5433 15.1478 20.7529C15.1478 18.9625 13.6912 17.5059 11.9008 17.5059Z" />
  </svg>
);

export default Question;
