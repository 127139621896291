/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Cut = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M14.5341 12L22.335 4.19859C22.5548 3.97875 22.5548 3.6225 22.335 3.40312C20.7975 1.86562 18.3042 1.86562 16.7667 3.40312L11.3522 8.81812L10.1869 7.65281C10.3889 7.14094 10.5 6.58359 10.5 6C10.5 3.51469 8.48531 1.5 6 1.5C3.51469 1.5 1.5 3.51469 1.5 6C1.5 8.48531 3.51469 10.5 6 10.5C6.21281 10.5 6.42141 10.485 6.62625 10.4564L8.16984 12L6.62625 13.5436C6.42141 13.515 6.21234 13.5 6 13.5C3.51469 13.5 1.5 15.5147 1.5 18C1.5 20.4853 3.51469 22.5 6 22.5C8.48531 22.5 10.5 20.4853 10.5 18C10.5 17.4164 10.3889 16.8591 10.1869 16.3472L11.3522 15.1819L16.7667 20.5969C18.3042 22.1344 20.7975 22.1344 22.335 20.5969C22.5548 20.3775 22.5548 20.0212 22.335 19.8014L14.5341 12ZM6 7.5C5.17312 7.5 4.5 6.82687 4.5 6C4.5 5.17313 5.17312 4.5 6 4.5C6.82687 4.5 7.5 5.17313 7.5 6C7.5 6.82687 6.82687 7.5 6 7.5ZM6 19.5C5.17312 19.5 4.5 18.8269 4.5 18C4.5 17.1731 5.17312 16.5 6 16.5C6.82687 16.5 7.5 17.1731 7.5 18C7.5 18.8269 6.82687 19.5 6 19.5Z" />
  </svg>
);

export default Cut;
