/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Star = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M10.8042 2.07502L8.08334 7.59169L1.99584 8.47919C0.904176 8.63752 0.466676 9.98335 1.25834 10.7542L5.66251 15.0459L4.62084 21.1084C4.43334 22.2042 5.58751 23.025 6.55418 22.5125L12 19.65L17.4458 22.5125C18.4125 23.0209 19.5667 22.2042 19.3792 21.1084L18.3375 15.0459L22.7417 10.7542C23.5333 9.98335 23.0958 8.63752 22.0042 8.47919L15.9167 7.59169L13.1958 2.07502C12.7083 1.09169 11.2958 1.07919 10.8042 2.07502Z" />
  </svg>
);

export default Star;
