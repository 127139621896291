/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const ShieldAlt = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M21.8672 3.92343L12.8672 0.173428C12.593 0.0596997 12.2991 0.00115967 12.0023 0.00115967C11.7055 0.00115967 11.4117 0.0596997 11.1375 0.173428L2.1375 3.92343C1.29844 4.2703 0.75 5.09062 0.75 5.99999C0.75 15.3047 6.11719 21.7359 11.1328 23.8266C11.6859 24.0562 12.3094 24.0562 12.8625 23.8266C16.8797 22.1531 23.25 16.3734 23.25 5.99999C23.25 5.09062 22.7016 4.2703 21.8672 3.92343ZM12.0047 20.9203L12 3.06093L20.2453 6.49687C20.0906 13.5937 16.3969 18.7359 12.0047 20.9203Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default ShieldAlt;
