/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const DeviceEyeWash = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M11.9156 0C9.4142 0 7.41122 1.04561 5.9531 2.29011C4.50427 3.52325 3.53529 5.00074 3.07711 6.0151C2.97495 6.23956 2.97495 6.4896 3.07711 6.71407C3.53529 7.72842 4.50427 9.20592 5.9531 10.4391C7.41122 11.6836 9.4142 12.7292 11.9156 12.7292C14.417 12.7292 16.42 11.6836 17.8781 10.4391C19.3269 9.20307 20.2959 7.72842 20.7572 6.71407C20.8594 6.4896 20.8594 6.23956 20.7572 6.0151C20.2959 5.00074 19.3269 3.52325 17.8781 2.29011C16.42 1.04561 14.417 0 11.9156 0ZM7.45766 6.36458C7.45766 5.27945 7.92733 4.23875 8.76336 3.47144C9.59939 2.70413 10.7333 2.27307 11.9156 2.27307C13.0979 2.27307 14.2318 2.70413 15.0678 3.47144C15.9039 4.23875 16.3735 5.27945 16.3735 6.36458C16.3735 7.44972 15.9039 8.49042 15.0678 9.25772C14.2318 10.025 13.0979 10.4561 11.9156 10.4561C10.7333 10.4561 9.59939 10.025 8.76336 9.25772C7.92733 8.49042 7.45766 7.44972 7.45766 6.36458ZM11.9156 4.54613C11.9156 5.54912 11.0271 6.36458 9.93429 6.36458C9.71449 6.36458 9.50398 6.33049 9.30585 6.27082C9.13558 6.21968 8.93745 6.31628 8.94364 6.48108C8.95293 6.67713 8.98388 6.87318 9.0427 7.06923C9.46683 8.524 11.0983 9.38776 12.6834 8.9985C14.2684 8.60924 15.2095 7.11185 14.7854 5.65709C14.4418 4.47794 13.3056 3.68521 12.0425 3.6369C11.863 3.63122 11.7577 3.81023 11.8134 3.96934C11.8785 4.15119 11.9156 4.3444 11.9156 4.54613Z" />
      <path d="M13.4997 23.3646V32.3646H11.9997V19.3646H18.4997V20.3646C18.4997 20.9168 18.0534 21.3671 17.5022 21.3993C15.796 21.4985 13.4997 21.8822 13.4997 23.3646Z" />
      <path d="M21.5244 9.98958C21.5244 10.3348 21.2446 10.6146 20.8994 10.6146C20.5542 10.6146 20.2744 10.3348 20.2744 9.98958C20.2744 9.64441 20.5542 9.36458 20.8994 9.36458C21.2446 9.36458 21.5244 9.64441 21.5244 9.98958Z" />
      <path d="M18.5197 11.9896C18.5197 12.3348 18.2399 12.6146 17.8947 12.6146C17.5495 12.6146 17.2697 12.3348 17.2697 11.9896C17.2697 11.6444 17.5495 11.3646 17.8947 11.3646C18.2399 11.3646 18.5197 11.6444 18.5197 11.9896Z" />
      <path d="M20.4197 11.9896C20.4197 12.3348 20.1399 12.6146 19.7947 12.6146C19.4495 12.6146 19.1697 12.3348 19.1697 11.9896C19.1697 11.6444 19.4495 11.3646 19.7947 11.3646C20.1399 11.3646 20.4197 11.6444 20.4197 11.9896Z" />
      <path d="M12.6247 13.9896C12.6247 14.3348 12.3449 14.6146 11.9997 14.6146C11.6545 14.6146 11.3747 14.3348 11.3747 13.9896C11.3747 13.6444 11.6545 13.3646 11.9997 13.3646C12.3449 13.3646 12.6247 13.6444 12.6247 13.9896Z" />
      <path d="M14.8297 13.9896C14.8297 14.3348 14.5499 14.6146 14.2047 14.6146C13.8595 14.6146 13.5797 14.3348 13.5797 13.9896C13.5797 13.6444 13.8595 13.3646 14.2047 13.3646C14.5499 13.3646 14.8297 13.6444 14.8297 13.9896Z" />
      <path d="M16.9197 13.9896C16.9197 14.3348 16.6399 14.6146 16.2947 14.6146C15.9495 14.6146 15.6697 14.3348 15.6697 13.9896C15.6697 13.6444 15.9495 13.3646 16.2947 13.3646C16.6399 13.3646 16.9197 13.6444 16.9197 13.9896Z" />
      <path d="M18.8297 13.9896C18.8297 14.3348 18.5499 14.6146 18.2047 14.6146C17.8595 14.6146 17.5797 14.3348 17.5797 13.9896C17.5797 13.6444 17.8595 13.3646 18.2047 13.3646C18.5499 13.3646 18.8297 13.6444 18.8297 13.9896Z" />
      <path d="M12.6247 15.9896C12.6247 16.3348 12.3449 16.6146 11.9997 16.6146C11.6545 16.6146 11.3747 16.3348 11.3747 15.9896C11.3747 15.6444 11.6545 15.3646 11.9997 15.3646C12.3449 15.3646 12.6247 15.6444 12.6247 15.9896Z" />
      <path d="M14.4197 15.9896C14.4197 16.3348 14.1399 16.6146 13.7947 16.6146C13.4495 16.6146 13.1697 16.3348 13.1697 15.9896C13.1697 15.6444 13.4495 15.3646 13.7947 15.3646C14.1399 15.3646 14.4197 15.6444 14.4197 15.9896Z" />
      <path d="M16.1246 15.9896C16.1246 16.3348 15.8448 16.6146 15.4996 16.6146C15.1545 16.6146 14.8746 16.3348 14.8746 15.9896C14.8746 15.6444 15.1545 15.3646 15.4996 15.3646C15.8448 15.3646 16.1246 15.6444 16.1246 15.9896Z" />
      <path d="M17.8197 15.9896C17.8197 16.3348 17.5399 16.6146 17.1947 16.6146C16.8495 16.6146 16.5697 16.3348 16.5697 15.9896C16.5697 15.6444 16.8495 15.3646 17.1947 15.3646C17.5399 15.3646 17.8197 15.6444 17.8197 15.9896Z" />
      <path d="M12.6247 17.9896C12.6247 18.3348 12.3449 18.6146 11.9997 18.6146C11.6545 18.6146 11.3747 18.3348 11.3747 17.9896C11.3747 17.6444 11.6545 17.3646 11.9997 17.3646C12.3449 17.3646 12.6247 17.6444 12.6247 17.9896Z" />
      <path d="M14.1247 17.9896C14.1247 18.3348 13.8449 18.6146 13.4997 18.6146C13.1545 18.6146 12.8747 18.3348 12.8747 17.9896C12.8747 17.6444 13.1545 17.3646 13.4997 17.3646C13.8449 17.3646 14.1247 17.6444 14.1247 17.9896Z" />
      <path d="M15.6247 17.9896C15.6247 18.3348 15.3449 18.6146 14.9997 18.6146C14.6545 18.6146 14.3747 18.3348 14.3747 17.9896C14.3747 17.6444 14.6545 17.3646 14.9997 17.3646C15.3449 17.3646 15.6247 17.6444 15.6247 17.9896Z" />
      <path d="M17.1247 17.9896C17.1247 18.3348 16.8449 18.6146 16.4997 18.6146C16.1545 18.6146 15.8747 18.3348 15.8747 17.9896C15.8747 17.6444 16.1545 17.3646 16.4997 17.3646C16.8449 17.3646 17.1247 17.6444 17.1247 17.9896Z" />
      <path d="M10.4997 23.3646V32.3646H11.9997V19.3646H5.49971V20.3646C5.49971 20.9168 5.946 21.3671 6.49727 21.3993C8.20345 21.4985 10.4997 21.8822 10.4997 23.3646Z" />
      <path d="M2.47501 9.98958C2.47501 10.3348 2.75483 10.6146 3.10001 10.6146C3.44518 10.6146 3.72501 10.3348 3.72501 9.98958C3.72501 9.64441 3.44518 9.36458 3.10001 9.36458C2.75483 9.36458 2.47501 9.64441 2.47501 9.98958Z" />
      <path d="M5.47971 11.9896C5.47971 12.3348 5.75954 12.6146 6.10471 12.6146C6.44989 12.6146 6.72971 12.3348 6.72971 11.9896C6.72971 11.6444 6.44989 11.3646 6.10471 11.3646C5.75954 11.3646 5.47971 11.6444 5.47971 11.9896Z" />
      <path d="M3.57971 11.9896C3.57971 12.3348 3.85953 12.6146 4.20471 12.6146C4.54989 12.6146 4.82971 12.3348 4.82971 11.9896C4.82971 11.6444 4.54989 11.3646 4.20471 11.3646C3.85953 11.3646 3.57971 11.6444 3.57971 11.9896Z" />
      <path d="M11.3747 13.9896C11.3747 14.3348 11.6545 14.6146 11.9997 14.6146C12.3449 14.6146 12.6247 14.3348 12.6247 13.9896C12.6247 13.6444 12.3449 13.3646 11.9997 13.3646C11.6545 13.3646 11.3747 13.6444 11.3747 13.9896Z" />
      <path d="M9.16971 13.9896C9.16971 14.3348 9.44953 14.6146 9.79471 14.6146C10.1399 14.6146 10.4197 14.3348 10.4197 13.9896C10.4197 13.6444 10.1399 13.3646 9.79471 13.3646C9.44953 13.3646 9.16971 13.6444 9.16971 13.9896Z" />
      <path d="M7.07971 13.9896C7.07971 14.3348 7.35953 14.6146 7.70471 14.6146C8.04989 14.6146 8.32971 14.3348 8.32971 13.9896C8.32971 13.6444 8.04989 13.3646 7.70471 13.3646C7.35953 13.3646 7.07971 13.6444 7.07971 13.9896Z" />
      <path d="M5.16971 13.9896C5.16971 14.3348 5.44953 14.6146 5.79471 14.6146C6.13989 14.6146 6.41971 14.3348 6.41971 13.9896C6.41971 13.6444 6.13989 13.3646 5.79471 13.3646C5.44953 13.3646 5.16971 13.6444 5.16971 13.9896Z" />
      <path d="M11.3747 15.9896C11.3747 16.3348 11.6545 16.6146 11.9997 16.6146C12.3449 16.6146 12.6247 16.3348 12.6247 15.9896C12.6247 15.6444 12.3449 15.3646 11.9997 15.3646C11.6545 15.3646 11.3747 15.6444 11.3747 15.9896Z" />
      <path d="M9.57971 15.9896C9.57971 16.3348 9.85953 16.6146 10.2047 16.6146C10.5499 16.6146 10.8297 16.3348 10.8297 15.9896C10.8297 15.6444 10.5499 15.3646 10.2047 15.3646C9.85953 15.3646 9.57971 15.6444 9.57971 15.9896Z" />
      <path d="M7.87479 15.9896C7.87479 16.3348 8.15461 16.6146 8.49979 16.6146C8.84496 16.6146 9.12479 16.3348 9.12479 15.9896C9.12479 15.6444 8.84496 15.3646 8.49979 15.3646C8.15461 15.3646 7.87479 15.6444 7.87479 15.9896Z" />
      <path d="M6.17972 15.9896C6.17972 16.3348 6.45954 16.6146 6.80472 16.6146C7.1499 16.6146 7.42972 16.3348 7.42972 15.9896C7.42972 15.6444 7.1499 15.3646 6.80472 15.3646C6.45954 15.3646 6.17972 15.6444 6.17972 15.9896Z" />
      <path d="M11.3747 17.9896C11.3747 18.3348 11.6545 18.6146 11.9997 18.6146C12.3449 18.6146 12.6247 18.3348 12.6247 17.9896C12.6247 17.6444 12.3449 17.3646 11.9997 17.3646C11.6545 17.3646 11.3747 17.6444 11.3747 17.9896Z" />
      <path d="M9.87471 17.9896C9.87471 18.3348 10.1545 18.6146 10.4997 18.6146C10.8449 18.6146 11.1247 18.3348 11.1247 17.9896C11.1247 17.6444 10.8449 17.3646 10.4997 17.3646C10.1545 17.3646 9.87471 17.6444 9.87471 17.9896Z" />
      <path d="M8.37471 17.9896C8.37471 18.3348 8.65453 18.6146 8.99971 18.6146C9.34489 18.6146 9.62471 18.3348 9.62471 17.9896C9.62471 17.6444 9.34489 17.3646 8.99971 17.3646C8.65453 17.3646 8.37471 17.6444 8.37471 17.9896Z" />
      <path d="M6.87471 17.9896C6.87471 18.3348 7.15453 18.6146 7.49971 18.6146C7.84489 18.6146 8.12471 18.3348 8.12471 17.9896C8.12471 17.6444 7.84489 17.3646 7.49971 17.3646C7.15453 17.3646 6.87471 17.6444 6.87471 17.9896Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default DeviceEyeWash;
