/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const ToggleOn = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M16 4H8C3.58333 4 0 7.58333 0 12C0 16.4167 3.58333 20 8 20H16C20.4167 20 24 16.4167 24 12C24 7.58333 20.4167 4 16 4ZM16 17.3333C13.05 17.3333 10.6667 14.9458 10.6667 12C10.6667 9.05 13.0542 6.66667 16 6.66667C18.95 6.66667 21.3333 9.05417 21.3333 12C21.3333 14.95 18.9458 17.3333 16 17.3333Z" />
  </svg>
);

export default ToggleOn;
