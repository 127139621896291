/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Users = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M5.4 2.39999C6.19565 2.39999 6.95871 2.71606 7.52132 3.27867C8.08393 3.84128 8.4 4.60434 8.4 5.39999C8.4 6.19564 8.08393 6.95871 7.52132 7.52131C6.95871 8.08392 6.19565 8.39999 5.4 8.39999C4.60435 8.39999 3.84129 8.08392 3.27868 7.52131C2.71607 6.95871 2.4 6.19564 2.4 5.39999C2.4 4.60434 2.71607 3.84128 3.27868 3.27867C3.84129 2.71606 4.60435 2.39999 5.4 2.39999ZM19.2 2.39999C19.9956 2.39999 20.7587 2.71606 21.3213 3.27867C21.8839 3.84128 22.2 4.60434 22.2 5.39999C22.2 6.19564 21.8839 6.95871 21.3213 7.52131C20.7587 8.08392 19.9956 8.39999 19.2 8.39999C18.4044 8.39999 17.6413 8.08392 17.0787 7.52131C16.5161 6.95871 16.2 6.19564 16.2 5.39999C16.2 4.60434 16.5161 3.84128 17.0787 3.27867C17.6413 2.71606 18.4044 2.39999 19.2 2.39999ZM0 13.6012C0 11.3925 1.7925 9.59999 4.00125 9.59999H5.6025C6.19875 9.59999 6.765 9.73124 7.275 9.96374C7.22625 10.2337 7.20375 10.515 7.20375 10.8C7.20375 12.2325 7.83375 13.5187 8.8275 14.4H0.79875C0.36 14.4 0 14.04 0 13.6012ZM15.1988 14.4H15.1725C16.17 13.5187 16.7963 12.2325 16.7963 10.8C16.7963 10.515 16.77 10.2375 16.725 9.96374C17.235 9.72749 17.8013 9.59999 18.3975 9.59999H19.9988C22.2075 9.59999 24 11.3925 24 13.6012C24 14.0437 23.64 14.4 23.2013 14.4H15.1988ZM8.4 10.8C8.4 9.84521 8.77929 8.92954 9.45442 8.25441C10.1295 7.57928 11.0452 7.19999 12 7.19999C12.9548 7.19999 13.8705 7.57928 14.5456 8.25441C15.2207 8.92954 15.6 9.84521 15.6 10.8C15.6 11.7548 15.2207 12.6704 14.5456 13.3456C13.8705 14.0207 12.9548 14.4 12 14.4C11.0452 14.4 10.1295 14.0207 9.45442 13.3456C8.77929 12.6704 8.4 11.7548 8.4 10.8ZM4.8 20.5987C4.8 17.8387 7.03875 15.6 9.79875 15.6H14.2013C16.9613 15.6 19.2 17.8387 19.2 20.5987C19.2 21.15 18.7537 21.6 18.1987 21.6H5.80125C5.25 21.6 4.8 21.1537 4.8 20.5987Z" />
  </svg>
);

export default Users;
