/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CommentDots = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M12 1.5C5.37188 1.5 0 5.86406 0 11.25C0 13.575 1.00313 15.7031 2.67188 17.3766C2.08594 19.7391 0.126563 21.8438 0.103125 21.8672C0 21.975 -0.028125 22.1344 0.0328125 22.275C0.09375 22.4156 0.225 22.5 0.375 22.5C3.48281 22.5 5.8125 21.0094 6.96563 20.0906C8.49844 20.6672 10.2 21 12 21C18.6281 21 24 16.6359 24 11.25C24 5.86406 18.6281 1.5 12 1.5ZM6 12.75C5.17031 12.75 4.5 12.0797 4.5 11.25C4.5 10.4203 5.17031 9.75 6 9.75C6.82969 9.75 7.5 10.4203 7.5 11.25C7.5 12.0797 6.82969 12.75 6 12.75ZM12 12.75C11.1703 12.75 10.5 12.0797 10.5 11.25C10.5 10.4203 11.1703 9.75 12 9.75C12.8297 9.75 13.5 10.4203 13.5 11.25C13.5 12.0797 12.8297 12.75 12 12.75ZM18 12.75C17.1703 12.75 16.5 12.0797 16.5 11.25C16.5 10.4203 17.1703 9.75 18 9.75C18.8297 9.75 19.5 10.4203 19.5 11.25C19.5 12.0797 18.8297 12.75 18 12.75Z" />
  </svg>
);

export default CommentDots;
