/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CaretDown = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M5.96719 9H18.0281C18.8625 9 19.2797 10.0078 18.6891 10.5984L12.6609 16.6312C12.2953 16.9969 11.7 16.9969 11.3344 16.6312L5.30625 10.5984C4.71563 10.0078 5.13281 9 5.96719 9Z" />
  </svg>
);

export default CaretDown;
