/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const PersonSlipping = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <rect x="12" y="22" width="12" height="2" rx="1" />
      <path d="M2.14185 4.70836C1.60535 4.44709 1.19462 3.98339 1 3.41928C0.805383 2.85517 0.842829 2.23686 1.1041 1.70036C1.36537 1.16386 1.82906 0.753118 2.39317 0.558501C2.95728 0.363884 3.5756 0.401329 4.1121 0.6626C4.6486 0.92387 5.05934 1.38756 5.25395 1.95167C5.44857 2.51578 5.41113 3.1341 5.14986 3.6706C4.88858 4.2071 4.42489 4.61784 3.86078 4.81246C3.29667 5.00707 2.67836 4.96963 2.14185 4.70836ZM14.183 15.7588L12.0663 13.4246C12.2359 13.3976 12.4031 13.354 12.5724 13.3061L15.2789 12.5021L16.5069 13.8561C16.7105 14.0804 16.8684 14.345 16.9678 14.6332L18.3018 18.4998C18.572 19.2831 18.1554 20.1386 17.3721 20.4089C16.5887 20.6791 15.7332 20.2625 15.463 19.4792L14.183 15.7588Z" />
      <path d="M9.70266 3.31097C7.61962 3.18811 5.51242 4.48372 4.59707 6.36331L1.68362 12.6235C1.34326 13.3788 1.67961 14.2669 2.43489 14.6073C3.19017 14.9477 4.07836 14.6113 4.41872 13.856L6.49999 9.00003C6.49999 9.00003 6.48664 8.99828 6.99331 9.24915C7.49999 9.50003 9.32256 11.2646 9.32256 11.2646C10.0969 11.9337 11.158 12.1636 12.1402 11.8703L16.8405 10.4732L21.0048 11.3541C21.8179 11.5259 22.6113 11.0103 22.783 10.1972C22.9548 9.38404 22.435 8.58864 21.6261 8.41891L17.2726 7.498C16.9029 7.42221 16.5257 7.43664 16.1662 7.54312L12.8746 8.52096L10.5103 6.36331L11.6766 6.43077C12.2178 6.46494 12.7465 6.30008 13.177 5.97273L14.2876 5.11625C14.9448 4.61252 15.0691 3.67202 14.5654 3.01481C14.0616 2.35761 13.1211 2.23328 12.4639 2.73701L11.5738 3.41928L9.70266 3.31097Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default PersonSlipping;
