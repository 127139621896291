/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const RotateLeft = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M24 12C24 13.625 23.6823 15.1771 23.0469 16.6562C22.4115 18.1354 21.5573 19.4115 20.4844 20.4844C19.4115 21.5573 18.1354 22.4115 16.6562 23.0469C15.1771 23.6823 13.625 24 12 24C10.2083 24 8.50521 23.6224 6.89062 22.8672C5.27604 22.112 3.90104 21.0469 2.76562 19.6719C2.69271 19.5677 2.65885 19.4505 2.66406 19.3203C2.66927 19.1901 2.71354 19.0833 2.79688 19L4.9375 16.8438C5.04167 16.75 5.17188 16.7031 5.32812 16.7031C5.49479 16.724 5.61458 16.7865 5.6875 16.8906C6.44792 17.8802 7.38021 18.6458 8.48438 19.1875C9.58854 19.7292 10.7604 20 12 20C13.0833 20 14.1172 19.7891 15.1016 19.3672C16.0859 18.9453 16.9375 18.375 17.6562 17.6562C18.375 16.9375 18.9453 16.0859 19.3672 15.1016C19.7891 14.1172 20 13.0833 20 12C20 10.9167 19.7891 9.88281 19.3672 8.89844C18.9453 7.91406 18.375 7.0625 17.6562 6.34375C16.9375 5.625 16.0859 5.05469 15.1016 4.63281C14.1172 4.21094 13.0833 4 12 4C10.9792 4 10 4.1849 9.0625 4.55469C8.125 4.92448 7.29167 5.45312 6.5625 6.14062L8.70312 8.29688C9.02604 8.60938 9.09896 8.96875 8.92188 9.375C8.74479 9.79167 8.4375 10 8 10H1C0.729167 10 0.494792 9.90104 0.296875 9.70312C0.0989583 9.50521 0 9.27083 0 9V2C0 1.5625 0.208333 1.25521 0.625 1.07812C1.03125 0.901042 1.39062 0.973958 1.70312 1.29688L3.73438 3.3125C4.84896 2.26042 6.1224 1.44531 7.55469 0.867188C8.98698 0.289062 10.4688 0 12 0C13.625 0 15.1771 0.317708 16.6562 0.953125C18.1354 1.58854 19.4115 2.44271 20.4844 3.51562C21.5573 4.58854 22.4115 5.86458 23.0469 7.34375C23.6823 8.82292 24 10.375 24 12Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default RotateLeft;
