/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const LayerGroup = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M0.581722 6.93842L11.5008 11.8917C11.8195 12.0365 12.18 12.0365 12.4988 11.8917L23.4178 6.93842C24.1936 6.58639 24.1936 5.41405 23.4178 5.06201L12.4992 0.108265C12.3426 0.0366825 12.1724 -0.000366211 12.0002 -0.000366211C11.828 -0.000366211 11.6579 0.0366825 11.5013 0.108265L0.581722 5.06155C-0.19406 5.41358 -0.19406 6.58639 0.581722 6.93842ZM23.4183 11.0765L20.6953 9.84233L13.1184 13.2769C12.7641 13.4376 12.3877 13.5192 12 13.5192C11.6123 13.5192 11.2364 13.4376 10.8816 13.2769L3.30516 9.84233L0.581722 11.0765C-0.19406 11.4281 -0.19406 12.6 0.581722 12.9515L11.5008 17.9011C11.8195 18.0455 12.18 18.0455 12.4988 17.9011L23.4183 12.9515C24.1941 12.6 24.1941 11.4281 23.4183 11.0765ZM23.4183 17.0672L20.7056 15.8376L13.1184 19.2769C12.7641 19.4376 12.3877 19.5192 12 19.5192C11.6123 19.5192 11.2364 19.4376 10.8816 19.2769L3.29485 15.8376L0.581722 17.0672C-0.19406 17.4187 -0.19406 18.5906 0.581722 18.9422L11.5008 23.8917C11.8195 24.0361 12.18 24.0361 12.4988 23.8917L23.4183 18.9422C24.1941 18.5906 24.1941 17.4187 23.4183 17.0672Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default LayerGroup;
