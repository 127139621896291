/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const HeartOutline = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M21.4874 3.01411C18.778 0.735988 14.5921 1.07818 11.9999 3.71724C9.4077 1.07818 5.22176 0.731301 2.51238 3.01411C-1.01262 5.9813 -0.496992 10.8188 2.01551 13.3829L10.2374 21.7594C10.7061 22.2376 11.3343 22.5047 11.9999 22.5047C12.6702 22.5047 13.2936 22.2422 13.7624 21.7641L21.9843 13.3876C24.4921 10.8235 25.0171 5.98599 21.4874 3.01411ZM20.3811 11.8032L12.1593 20.1797C12.0468 20.2922 11.953 20.2922 11.8405 20.1797L3.61863 11.8032C1.9077 10.0594 1.56082 6.75943 3.96082 4.73911C5.78426 3.2063 8.59676 3.43599 10.3593 5.2313L11.9999 6.90474L13.6405 5.2313C15.4124 3.42661 18.2249 3.2063 20.0389 4.73443C22.4343 6.75474 22.078 10.0735 20.3811 11.8032Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default HeartOutline;
