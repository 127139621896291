/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CaretUp = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M18.031 16.5H5.96897C5.13375 16.5 4.71549 15.4902 5.30606 14.8996L11.3371 8.86856C11.7032 8.50247 12.2968 8.50247 12.6629 8.86856L18.6939 14.8996C19.2846 15.4902 18.8663 16.5 18.031 16.5Z" />
  </svg>
);

export default CaretUp;
