/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const ExpandAlt = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M11.4697 14.7803L7.125 19.125L8.66822 20.5795C9.37697 21.2882 8.87503 22.5 7.87275 22.5H2.62275C2.00142 22.5 1.5 21.9963 1.5 21.375V16.125C1.5 15.1227 2.70952 14.6208 3.41822 15.3295L4.875 16.875L9.21966 12.5303C9.51253 12.2375 9.98742 12.2375 10.2803 12.5303L11.4697 13.7197C11.7626 14.0126 11.7626 14.4874 11.4697 14.7803ZM12.5303 9.21966L16.875 4.875L15.3318 3.42052C14.623 2.71177 15.125 1.5 16.1273 1.5H21.3773C21.9986 1.5 22.5 2.00367 22.5 2.625V7.875C22.5 8.87728 21.2905 9.37922 20.5818 8.67052L19.125 7.125L14.7803 11.4697C14.4875 11.7625 14.0126 11.7625 13.7197 11.4697L12.5303 10.2803C12.2374 9.98742 12.2374 9.51258 12.5303 9.21966Z" />
  </svg>
);

export default ExpandAlt;
