/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const AddressCard = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M22 2.66501H2C0.895833 2.66501 0 3.56084 0 4.66501V19.3317C0 20.4358 0.895833 21.3317 2 21.3317H22C23.1042 21.3317 24 20.4358 24 19.3317V4.66501C24 3.56084 23.1042 2.66501 22 2.66501ZM7.33333 6.66501C8.80417 6.66501 10 7.86084 10 9.33168C10 10.8025 8.80417 11.9983 7.33333 11.9983C5.8625 11.9983 4.66667 10.8025 4.66667 9.33168C4.66667 7.86084 5.8625 6.66501 7.33333 6.66501ZM12 16.5317C12 16.9733 11.5833 17.3317 11.0667 17.3317H3.6C3.08333 17.3317 2.66667 16.9733 2.66667 16.5317V15.7317C2.66667 14.4067 3.92083 13.3317 5.46667 13.3317H5.675C6.1875 13.5442 6.74583 13.665 7.33333 13.665C7.92083 13.665 8.48333 13.5442 8.99167 13.3317H9.2C10.7458 13.3317 12 14.4067 12 15.7317V16.5317ZM21.3333 14.3317C21.3333 14.515 21.1833 14.665 21 14.665H15C14.8167 14.665 14.6667 14.515 14.6667 14.3317V13.665C14.6667 13.4817 14.8167 13.3317 15 13.3317H21C21.1833 13.3317 21.3333 13.4817 21.3333 13.665V14.3317ZM21.3333 11.665C21.3333 11.8483 21.1833 11.9983 21 11.9983H15C14.8167 11.9983 14.6667 11.8483 14.6667 11.665V10.9983C14.6667 10.815 14.8167 10.665 15 10.665H21C21.1833 10.665 21.3333 10.815 21.3333 10.9983V11.665ZM21.3333 8.99834C21.3333 9.18168 21.1833 9.33168 21 9.33168H15C14.8167 9.33168 14.6667 9.18168 14.6667 8.99834V8.33168C14.6667 8.14834 14.8167 7.99834 15 7.99834H21C21.1833 7.99834 21.3333 8.14834 21.3333 8.33168V8.99834Z" />
  </svg>
);

export default AddressCard;
