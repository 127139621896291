/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Eye = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M11.9997 3.60022C8.96979 3.60022 6.54358 4.9802 4.77737 6.62267C3.0224 8.25014 1.84867 10.2001 1.29368 11.5388C1.16993 11.8351 1.16993 12.1651 1.29368 12.4613C1.84867 13.8 3.0224 15.75 4.77737 17.3775C6.54358 19.0199 8.96979 20.3999 11.9997 20.3999C15.0297 20.3999 17.4559 19.0199 19.2221 17.3775C20.9771 15.7463 22.1508 13.8 22.7095 12.4613C22.8333 12.1651 22.8333 11.8351 22.7095 11.5388C22.1508 10.2001 20.9771 8.25014 19.2221 6.62267C17.4559 4.9802 15.0297 3.60022 11.9997 3.60022ZM6.59983 12.0001C6.59983 10.5679 7.16875 9.19444 8.18143 8.18176C9.19411 7.16908 10.5676 6.60017 11.9997 6.60017C13.4319 6.60017 14.8054 7.16908 15.818 8.18176C16.8307 9.19444 17.3996 10.5679 17.3996 12.0001C17.3996 13.4322 16.8307 14.8057 15.818 15.8184C14.8054 16.8311 13.4319 17.4 11.9997 17.4C10.5676 17.4 9.19411 16.8311 8.18143 15.8184C7.16875 14.8057 6.59983 13.4322 6.59983 12.0001ZM11.9997 9.60011C11.9997 10.9238 10.9235 12.0001 9.59978 12.0001C9.33354 12.0001 9.07854 11.9551 8.83854 11.8763C8.6323 11.8088 8.3923 11.9363 8.3998 12.1538C8.41105 12.4126 8.44855 12.6713 8.5198 12.9301C9.03354 14.85 11.0098 15.99 12.9297 15.4763C14.8497 14.9625 15.9897 12.9863 15.4759 11.0663C15.0597 9.51012 13.6835 8.46388 12.1535 8.40014C11.936 8.39264 11.8085 8.62888 11.876 8.83888C11.9547 9.07887 11.9997 9.33387 11.9997 9.60011Z" />
  </svg>
);

export default Eye;
