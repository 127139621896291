/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CleverFirstAidLogoPadded = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M2.95889 9.04112C2.95889 8.04246 3.76846 7.2329 4.76711 7.2329H6.73971V8.2192H4.76711C4.31318 8.2192 3.94519 8.58718 3.94519 9.04112V14.9589C3.94519 15.4129 4.31318 15.7808 4.76711 15.7808H10.0274C10.1182 15.7808 10.1918 15.7072 10.1918 15.6165V13.9726C10.1918 13.8818 10.1182 13.8082 10.0274 13.8082H8.38355C8.29276 13.8082 8.21917 13.8818 8.21917 13.9726V15.2877H7.23287V13.9726C7.23287 13.3371 7.74804 12.8219 8.38355 12.8219H10.0274C10.6629 12.8219 11.1781 13.3371 11.1781 13.9726V15.6165C11.1781 16.252 10.6629 16.7671 10.0274 16.7671H4.76711C3.76846 16.7671 2.95889 15.9576 2.95889 14.9589V9.04112Z"
    />
    <path
      fill-rule="evenodd"
      d="M21.0411 14.9589C21.0411 15.9576 20.2315 16.7671 19.2329 16.7671H17.2603V15.7808H19.2329C19.6868 15.7808 20.0548 15.4129 20.0548 14.9589L20.0548 9.04112C20.0548 8.58718 19.6868 8.2192 19.2329 8.2192L13.9726 8.2192C13.8818 8.2192 13.8082 8.2928 13.8082 8.38358L13.8082 10.0274C13.8082 10.1182 13.8818 10.1918 13.9726 10.1918L15.6164 10.1918C15.7072 10.1918 15.7808 10.1182 15.7808 10.0274L15.7808 8.71235H16.7671L16.7671 10.0274C16.7671 10.6629 16.2519 11.1781 15.6164 11.1781H13.9726C13.3371 11.1781 12.8219 10.6629 12.8219 10.0274V8.38358C12.8219 7.74808 13.3371 7.2329 13.9726 7.2329L19.2329 7.2329C20.2315 7.2329 21.0411 8.04246 21.0411 9.04112L21.0411 14.9589Z"
    />
    <path
      fill-rule="evenodd"
      d="M9.04108 21.0411C8.04243 21.0411 7.23287 20.2315 7.23287 19.2329L7.23286 17.2603H8.21917L8.21917 19.2329C8.21917 19.6868 8.58715 20.0548 9.04108 20.0548H14.9589C15.4128 20.0548 15.7808 19.6868 15.7808 19.2329V13.9726C15.7808 13.8818 15.7072 13.8082 15.6164 13.8082H13.9726C13.8818 13.8082 13.8082 13.8818 13.8082 13.9726V15.6165C13.8082 15.7072 13.8818 15.7808 13.9726 15.7808H15.2877V16.7671H13.9726C13.3371 16.7671 12.8219 16.252 12.8219 15.6165V13.9726C12.8219 13.3371 13.3371 12.8219 13.9726 12.8219H15.6164C16.2519 12.8219 16.7671 13.3371 16.7671 13.9726V19.2329C16.7671 20.2315 15.9575 21.0411 14.9589 21.0411H9.04108Z"
    />
    <path
      fill-rule="evenodd"
      d="M14.9589 2.95892C15.9575 2.95892 16.7671 3.76849 16.7671 4.76714V6.73974L15.7808 6.73975V4.76714C15.7808 4.31321 15.4128 3.94522 14.9589 3.94522L9.04108 3.94523C8.58715 3.94523 8.21917 4.31321 8.21917 4.76714L8.21917 10.0274C8.21917 10.1182 8.29277 10.1918 8.38355 10.1918H10.0274C10.1182 10.1918 10.1918 10.1182 10.1918 10.0274V8.38358C10.1918 8.2928 10.1182 8.2192 10.0274 8.2192H8.71232V7.2329L10.0274 7.2329C10.6629 7.2329 11.1781 7.74808 11.1781 8.38358V10.0274C11.1781 10.6629 10.6629 11.1781 10.0274 11.1781H8.38355C7.74805 11.1781 7.23287 10.6629 7.23287 10.0274L7.23287 4.76714C7.23287 3.76849 8.04243 2.95892 9.04108 2.95892L14.9589 2.95892Z"
    />
  </svg>
);

export default CleverFirstAidLogoPadded;
