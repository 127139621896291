/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CarMedical = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M19.8 9.60001H20.4C22.3883 9.60001 24 11.2118 24 13.2V16.2C24 16.5315 23.7315 16.8 23.4 16.8H21.6C21.6 18.7883 19.9883 20.4 18 20.4C16.0118 20.4 14.4 18.7883 14.4 16.8H9.6C9.6 18.7883 7.98825 20.4 6 20.4C4.01175 20.4 2.4 18.7883 2.4 16.8H0.6C0.2685 16.8 0 16.5315 0 16.2V12C0 10.8829 0.7665 9.95251 1.8 9.68475L3.59663 5.10863C3.96113 4.19738 4.8435 3.60001 5.82488 3.60001H13.8465C14.2064 3.60007 14.5618 3.68105 14.8862 3.83696C15.2106 3.99287 15.4958 4.21973 15.7208 4.50076L19.8 9.60001ZM4.2 16.8C4.2 17.7926 5.00738 18.6 6 18.6C6.99263 18.6 7.8 17.7926 7.8 16.8C7.8 15.8074 6.99263 15 6 15C5.00738 15 4.2 15.8074 4.2 16.8ZM16.2 16.8C16.2 17.7926 17.0074 18.6 18 18.6C18.9926 18.6 19.8 17.7926 19.8 16.8C19.8 15.8074 18.9926 15 18 15C17.0074 15 16.2 15.8074 16.2 16.8ZM14.2875 10.35C14.2875 10.515 14.1525 10.65 13.9875 10.65H11.8875V12.75C11.8875 12.915 11.7525 13.05 11.5875 13.05H9.7875C9.6225 13.05 9.4875 12.915 9.4875 12.75V10.65H7.3875C7.2225 10.65 7.0875 10.515 7.0875 10.35V8.55C7.0875 8.385 7.2225 8.25001 7.3875 8.25001H9.4875V6.15001C9.4875 5.98501 9.6225 5.85001 9.7875 5.85001H11.5875C11.7525 5.85001 11.8875 5.98501 11.8875 6.15001V8.25001H13.9875C14.1525 8.25001 14.2875 8.385 14.2875 8.55V10.35Z"
    />
  </svg>
);

export default CarMedical;
