/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CloudDownload = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M20.16 10.8975C20.3137 10.4963 20.4 10.0575 20.4 9.60001C20.4 7.61251 18.7875 6.00001 16.8 6.00001C16.0613 6.00001 15.3713 6.22501 14.8013 6.60751C13.7625 4.80751 11.8238 3.60001 9.6 3.60001C6.285 3.60001 3.6 6.28501 3.6 9.60001C3.6 9.70126 3.60375 9.80251 3.6075 9.90376C1.5075 10.6425 0 12.645 0 15C0 17.9813 2.41875 20.4 5.4 20.4H19.2C21.8513 20.4 24 18.2513 24 15.6C24 13.2788 22.35 11.34 20.16 10.8975ZM15.1763 14.2238L11.2238 18.1763C10.9913 18.4088 10.6088 18.4088 10.3763 18.1763L6.42375 14.2238C6.045 13.845 6.315 13.2 6.8475 13.2H9.3V9.00001C9.3 8.67001 9.57 8.40001 9.9 8.40001H11.7C12.03 8.40001 12.3 8.67001 12.3 9.00001V13.2H14.7525C15.285 13.2 15.555 13.845 15.1763 14.2238Z" />
  </svg>
);

export default CloudDownload;
