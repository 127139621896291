/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CintasTabletsGray = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M21.4126 11.378C23.5743 9.44578 23.5969 5.93784 21.4598 3.54715C19.3226 1.15498 15.8357 0.783504 13.6702 2.71797L2.58716 12.6222C0.423867 14.5566 0.402117 18.0608 2.54068 20.4544C4.67393 22.8445 8.16319 23.2159 10.3258 21.2815L21.4126 11.378Z"
        fill="white"
      />
      <path
        d="M17.1506 2.43598C16.0753 2.39777 15.066 2.75112 14.3058 3.42958L3.22366 13.333C1.45575 14.9132 1.46927 17.8232 3.2514 19.8193C4.1991 20.8801 5.50999 21.5158 6.84493 21.5638C7.92098 21.6014 8.93023 21.2487 9.68958 20.5695L20.7764 10.6669C22.5435 9.08585 22.5314 6.17739 20.7478 4.18299C19.7978 3.11967 18.4863 2.48399 17.1506 2.43598ZM6.77815 23.4705C4.92472 23.4052 3.12007 22.5378 1.82869 21.0903C-0.656528 18.3101 -0.601802 14.1921 1.95096 11.9103L13.034 2.00539C14.1633 0.997578 15.6491 0.473753 17.218 0.529434C19.0715 0.593933 20.8768 1.46197 22.1713 2.91033C24.6558 5.69046 24.6009 9.80844 22.0482 12.0889L10.9614 21.9923C9.83437 23.0007 8.34788 23.5267 6.77815 23.4705Z"
        fill="#75828B"
      />
      <path
        d="M15.869 16.3294L21.4127 11.3784C23.5745 9.44551 23.597 5.93833 21.4592 3.54764C19.3221 1.15466 15.836 0.782524 13.6696 2.71699L8.12885 7.67084"
        fill="#75828B"
      />
      <path
        d="M11.9291 7.97141C11.7092 7.96388 11.4931 7.87011 11.3348 7.69299C11.0278 7.34932 11.0564 6.81959 11.4016 6.51261L13.0081 5.07568C14.2972 3.80906 17.325 1.99918 19.6819 4.13629C20.0233 4.44768 20.0488 4.9751 19.739 5.31817C19.4283 5.65944 18.8992 5.68654 18.5571 5.37515C16.7765 3.75877 14.2747 6.17722 14.1682 6.28156L14.1389 6.30932L12.5159 7.75977C12.3485 7.90908 12.1369 7.97894 11.9291 7.97141Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CintasTabletsGray;
