/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const ClipboardList = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M18.75 3H15C15 1.34531 13.6547 0 12 0C10.3453 0 9 1.34531 9 3H5.25C4.00781 3 3 4.00781 3 5.25V21.75C3 22.9922 4.00781 24 5.25 24H18.75C19.9922 24 21 22.9922 21 21.75V5.25C21 4.00781 19.9922 3 18.75 3ZM7.5 19.875C6.87656 19.875 6.375 19.3734 6.375 18.75C6.375 18.1266 6.87656 17.625 7.5 17.625C8.12344 17.625 8.625 18.1266 8.625 18.75C8.625 19.3734 8.12344 19.875 7.5 19.875ZM7.5 15.375C6.87656 15.375 6.375 14.8734 6.375 14.25C6.375 13.6266 6.87656 13.125 7.5 13.125C8.12344 13.125 8.625 13.6266 8.625 14.25C8.625 14.8734 8.12344 15.375 7.5 15.375ZM7.5 10.875C6.87656 10.875 6.375 10.3734 6.375 9.75C6.375 9.12656 6.87656 8.625 7.5 8.625C8.12344 8.625 8.625 9.12656 8.625 9.75C8.625 10.3734 8.12344 10.875 7.5 10.875ZM12 1.875C12.6234 1.875 13.125 2.37656 13.125 3C13.125 3.62344 12.6234 4.125 12 4.125C11.3766 4.125 10.875 3.62344 10.875 3C10.875 2.37656 11.3766 1.875 12 1.875ZM18 19.125C18 19.3312 17.8313 19.5 17.625 19.5H10.875C10.6687 19.5 10.5 19.3312 10.5 19.125V18.375C10.5 18.1688 10.6687 18 10.875 18H17.625C17.8313 18 18 18.1688 18 18.375V19.125ZM18 14.625C18 14.8313 17.8313 15 17.625 15H10.875C10.6687 15 10.5 14.8313 10.5 14.625V13.875C10.5 13.6687 10.6687 13.5 10.875 13.5H17.625C17.8313 13.5 18 13.6687 18 13.875V14.625ZM18 10.125C18 10.3313 17.8313 10.5 17.625 10.5H10.875C10.6687 10.5 10.5 10.3313 10.5 10.125V9.375C10.5 9.16875 10.6687 9 10.875 9H17.625C17.8313 9 18 9.16875 18 9.375V10.125Z" />
  </svg>
);

export default ClipboardList;
