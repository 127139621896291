/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const User = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M11.9 11.6C14.5513 11.6 16.7 9.45126 16.7 6.80001C16.7 4.14877 14.5513 2.00002 11.9 2.00002C9.24875 2.00002 7.1 4.14877 7.1 6.80001C7.1 9.45126 9.24875 11.6 11.9 11.6ZM15.26 12.8H14.6337C13.8012 13.1825 12.875 13.4 11.9 13.4C10.925 13.4 10.0025 13.1825 9.16625 12.8H8.54C5.7575 12.8 3.5 15.0575 3.5 17.84V19.4C3.5 20.3938 4.30625 21.2 5.3 21.2H18.5C19.4938 21.2 20.3 20.3938 20.3 19.4V17.84C20.3 15.0575 18.0425 12.8 15.26 12.8Z" />
  </svg>
);

export default User;
