/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CalendarDays = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M7.50515 0C8.33347 0 9.00268 0.670312 9.00268 1.5V3H14.9928V1.5C14.9928 0.670312 15.662 0 16.4904 0C17.3187 0 17.9879 0.670312 17.9879 1.5V3H20.2342C21.4744 3 22.4805 4.00781 22.4805 5.25V7.5H1.515V5.25C1.515 4.00781 2.52116 3 3.7613 3H6.00761V1.5C6.00761 0.670312 6.67682 0 7.50515 0ZM1.515 9H22.4805V21.75C22.4805 22.9922 21.4744 24 20.2342 24H3.7613C2.52116 24 1.515 22.9922 1.515 21.75V9ZM4.51007 12.75V14.25C4.51007 14.6625 4.84702 15 5.25884 15H6.75638C7.1682 15 7.50515 14.6625 7.50515 14.25V12.75C7.50515 12.3375 7.1682 12 6.75638 12H5.25884C4.84702 12 4.51007 12.3375 4.51007 12.75ZM10.5002 12.75V14.25C10.5002 14.6625 10.8372 15 11.249 15H12.7465C13.1583 15 13.4953 14.6625 13.4953 14.25V12.75C13.4953 12.3375 13.1583 12 12.7465 12H11.249C10.8372 12 10.5002 12.3375 10.5002 12.75ZM17.2391 12C16.8273 12 16.4904 12.3375 16.4904 12.75V14.25C16.4904 14.6625 16.8273 15 17.2391 15H18.7367C19.1485 15 19.4854 14.6625 19.4854 14.25V12.75C19.4854 12.3375 19.1485 12 18.7367 12H17.2391ZM4.51007 18.75V20.25C4.51007 20.6625 4.84702 21 5.25884 21H6.75638C7.1682 21 7.50515 20.6625 7.50515 20.25V18.75C7.50515 18.3375 7.1682 18 6.75638 18H5.25884C4.84702 18 4.51007 18.3375 4.51007 18.75ZM11.249 18C10.8372 18 10.5002 18.3375 10.5002 18.75V20.25C10.5002 20.6625 10.8372 21 11.249 21H12.7465C13.1583 21 13.4953 20.6625 13.4953 20.25V18.75C13.4953 18.3375 13.1583 18 12.7465 18H11.249ZM16.4904 18.75V20.25C16.4904 20.6625 16.8273 21 17.2391 21H18.7367C19.1485 21 19.4854 20.6625 19.4854 20.25V18.75C19.4854 18.3375 19.1485 18 18.7367 18H17.2391C16.8273 18 16.4904 18.3375 16.4904 18.75Z" />
  </svg>
);

export default CalendarDays;
