/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const ArrowAltCircleRight = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M23.625 12C23.625 5.57812 18.4219 0.375 12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 18.4219 5.57812 23.625 12 23.625C18.4219 23.625 23.625 18.4219 23.625 12ZM2.625 12C2.625 6.82031 6.82031 2.625 12 2.625C17.1797 2.625 21.375 6.82031 21.375 12C21.375 17.1797 17.1797 21.375 12 21.375C6.82031 21.375 2.625 17.1797 2.625 12ZM6 12.9375V11.0625C6 10.7531 6.25313 10.5 6.5625 10.5H12V7.35938C12 6.85781 12.6047 6.60938 12.9609 6.96094L17.6016 11.6016C17.8219 11.8219 17.8219 12.1781 17.6016 12.3984L12.9609 17.0391C12.6047 17.3953 12 17.1422 12 16.6406V13.5H6.5625C6.25313 13.5 6 13.2469 6 12.9375Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowAltCircleRight;
