/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const MobileAndEnvelope = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M14 14.1378V3H21.75C22.9922 3 24 4.00781 24 5.25V6.14062C24 6.4875 23.8406 6.81562 23.5687 7.02656C23.4883 7.08964 23.4109 7.15043 23.3353 7.20976C22.0632 8.20867 21.3121 8.79843 15.4406 13.0594C15.3057 13.1573 15.148 13.2792 14.9738 13.4139C14.685 13.6371 14.3508 13.8953 14 14.1378ZM14 21V15.9098C14.6924 15.5311 15.3287 15.0389 15.8419 14.6419C16.019 14.5049 16.1815 14.3792 16.3266 14.2734C21.1078 10.8 22.4953 9.75938 23.5453 8.94375C23.7281 8.79844 24 8.93437 24 9.16406V18.75C24 19.9922 22.9922 21 21.75 21H14Z"
    />
    <path
      fill-rule="evenodd"
      d="M6.75 0C5.50781 0 4.5 1.00781 4.5 2.25V21.75C4.5 22.9922 5.50781 24 6.75 24H12V22.5C11.1703 22.5 10.5 21.8297 10.5 21C10.5 20.1703 11.1703 19.5 12 19.5V0H6.75Z"
    />
  </svg>
);

export default MobileAndEnvelope;
