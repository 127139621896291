/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

import Aed from "./Aed";
import AedInverted from "./AedInverted";
import AddressCard from "./AddressCard";
import AngleDoubleLeft from "./AngleDoubleLeft";
import AngleDoubleRight from "./AngleDoubleRight";
import AngleLeft from "./AngleLeft";
import AngleRight from "./AngleRight";
import ArrowAltCircleRight from "./ArrowAltCircleRight";
import ArrowAltLeft from "./ArrowAltLeft";
import ArrowAltRight from "./ArrowAltRight";
import ArrowDown from "./ArrowDown";
import ArrowLeft from "./ArrowLeft";
import ArrowRight from "./ArrowRight";
import ArrowUp from "./ArrowUp";
import ArrowUpRightFromSquare from "./ArrowUpRightFromSquare";
import BagMedical from "./BagMedical";
import Bars from "./Bars";
import Battery from "./Battery";
import Bell from "./Bell";
import Biohazard from "./Biohazard";
import Bluetooth from "./Bluetooth";
import BoxOpen from "./BoxOpen";
import BriefcaseMedical from "./BriefcaseMedical";
import Building from "./Building";
import CalendarCheck from "./CalendarCheck";
import CalendarDays from "./CalendarDays";
import Camera from "./Camera";
import CarMedical from "./CarMedical";
import CaretDown from "./CaretDown";
import CaretSquareDown from "./CaretSquareDown";
import CaretUp from "./CaretUp";
import CaretUpDown from "./CaretUpDown";
import ChartLine from "./ChartLine";
import Check from "./Check";
import CheckAltCircle from "./CheckAltCircle";
import CheckSquare from "./CheckSquare";
import CintasAntisepticsColor from "./CintasAntisepticsColor";
import CintasAntisepticsGray from "./CintasAntisepticsGray";
import CintasBandagesColor from "./CintasBandagesColor";
import CintasBandagesGray from "./CintasBandagesGray";
import CintasBurnsColor from "./CintasBurnsColor";
import CintasBurnsGray from "./CintasBurnsGray";
import CintasDevice from "./CintasDevice";
import CintasEmergencyColor from "./CintasEmergencyColor";
import CintasEmergencyGray from "./CintasEmergencyGray";
import CintasEyesColor from "./CintasEyesColor";
import CintasEyesGray from "./CintasEyesGray";
import CintasIncidents from "./CintasIncidents";
import CintasProductivityColor from "./CintasProductivityColor";
import CintasProductivityGray from "./CintasProductivityGray";
import CintasStore from "./CintasStore";
import CintasTabletsColor from "./CintasTabletsColor";
import CintasTabletsGray from "./CintasTabletsGray";
import CintasToolsColor from "./CintasToolsColor";
import CintasToolsGray from "./CintasToolsGray";
import CircleChecked from "./CircleChecked";
import CircleOutline from "./CircleOutline";
import CircleOutlineWithDot from "./CircleOutlineWithDot";
import CircleSolid from "./CircleSolid";
import CleverFirstAidLogo from "./CleverFirstAidLogo";
import CleverFirstAidLogoPadded from "./CleverFirstAidLogoPadded";
import ClipboardCheck from "./ClipboardCheck";
import ClipboardList from "./ClipboardList";
import Clock from "./Clock";
import CloudDownload from "./CloudDownload";
import CloudOffline from "./CloudOffline";
import CloudOfflineSolid from "./CloudOfflineSolid";
import CloudUpload from "./CloudUpload";
import Cogs from "./Cogs";
import CommentDots from "./CommentDots";
import Compress from "./Compress";
import CompressAlt from "./CompressAlt";
import Copy from "./Copy";
import Cube from "./Cube";
import Cut from "./Cut";
import DeviceAed from "./DeviceAed";
import DeviceCleverShelf from "./DeviceCleverShelf";
import DeviceEyeWash from "./DeviceEyeWash";
import DeviceFirstAidCabinet from "./DeviceFirstAidCabinet";
import Edit from "./Edit";
import EllipHorizontal from "./EllipHorizontal";
import Envelope from "./Envelope";
import Exclamation from "./Exclamation";
import ExclamationAltCircle from "./ExclamationAltCircle";
import ExclamationTriangle from "./ExclamationTriangle";
import Expand from "./Expand";
import ExpandAlt from "./ExpandAlt";
import Eye from "./Eye";
import EyeSlash from "./EyeSlash";
import Filter from "./Filter";
import FilterAlt from "./FilterAlt";
import FirstAidInverted from "./FirstAidInverted";
import Grid from "./Grid";
import HandShake from "./HandShake";
import Heart from "./Heart";
import HeartOutline from "./HeartOutline";
import History from "./History";
import Home from "./Home";
import HourglassEnd from "./HourglassEnd";
import Image from "./Image";
import Info from "./Info";
import InfoCircle from "./InfoCircle";
import Language from "./Language";
import LayerGroup from "./LayerGroup";
import LevelUpIn from "./LevelUpIn";
import LevelUpLeft from "./LevelUpLeft";
import LevelUpOut from "./LevelUpOut";
import LevelUpRight from "./LevelUpRight";
import Link from "./Link";
import List from "./List";
import LocationArrow from "./LocationArrow";
import LocationCrosshairs from "./LocationCrosshairs";
import Lock from "./Lock";
import MapMarkedAlt from "./MapMarkedAlt";
import MapPin from "./MapPin";
import Minimise from "./Minimise";
import Minus from "./Minus";
import Mobile from "./Mobile";
import MobileAlt from "./MobileAlt";
import MobileAndEnvelope from "./MobileAndEnvelope";
import Money from "./Money";
import Palette from "./Palette";
import PauseCircle from "./PauseCircle";
import PencilAlt from "./PencilAlt";
import PersonSlipping from "./PersonSlipping";
import Phone from "./Phone";
import Plaster from "./Plaster";
import Plug from "./Plug";
import Plus from "./Plus";
import PowerOff from "./PowerOff";
import ProxLogo from "./ProxLogo";
import Qr from "./Qr";
import Question from "./Question";
import QuestionCircle from "./QuestionCircle";
import RotateLeft from "./RotateLeft";
import Search from "./Search";
import Send from "./Send";
import Sensors from "./Sensors";
import ShieldAlt from "./ShieldAlt";
import Sidebar from "./Sidebar";
import SignIn from "./SignIn";
import SignOut from "./SignOut";
import SmartShelf from "./SmartShelf";
import Spinner from "./Spinner";
import Square from "./Square";
import SquareChecked from "./SquareChecked";
import SquareO from "./SquareO";
import Star from "./Star";
import StarOutline from "./StarOutline";
import TachometerAlt from "./TachometerAlt";
import Temperature from "./Temperature";
import Times from "./Times";
import Tint from "./Tint";
import ToggleOff from "./ToggleOff";
import ToggleOn from "./ToggleOn";
import TrashAlt from "./TrashAlt";
import Truck from "./Truck";
import User from "./User";
import UserFriends from "./UserFriends";
import UserMinus from "./UserMinus";
import UserPlus from "./UserPlus";
import Users from "./Users";
import ZoomIn from "./ZoomIn";
import ZoomOut from "./ZoomOut";

const icons = {
  aed: {
    Icon: Aed,
    meta: null,
  },
  "aed-inverted": {
    Icon: AedInverted,
    meta: null,
  },
  "address-card": {
    Icon: AddressCard,
    meta: null,
  },
  "angle-double-left": {
    Icon: AngleDoubleLeft,
    meta: null,
  },
  "angle-double-right": {
    Icon: AngleDoubleRight,
    meta: null,
  },
  "angle-left": {
    Icon: AngleLeft,
    meta: null,
  },
  "angle-right": {
    Icon: AngleRight,
    meta: null,
  },
  "arrow-alt-circle-right": {
    Icon: ArrowAltCircleRight,
    meta: null,
  },
  "arrow-alt-left": {
    Icon: ArrowAltLeft,
    meta: null,
  },
  "arrow-alt-right": {
    Icon: ArrowAltRight,
    meta: null,
  },
  "arrow-down": {
    Icon: ArrowDown,
    meta: null,
  },
  "arrow-left": {
    Icon: ArrowLeft,
    meta: null,
  },
  "arrow-right": {
    Icon: ArrowRight,
    meta: null,
  },
  "arrow-up": {
    Icon: ArrowUp,
    meta: null,
  },
  "arrow-up-right-from-square": {
    Icon: ArrowUpRightFromSquare,
    meta: null,
  },
  "bag-medical": {
    Icon: BagMedical,
    meta: null,
  },
  bars: {
    Icon: Bars,
    meta: null,
  },
  battery: {
    Icon: Battery,
    meta: null,
  },
  bell: {
    Icon: Bell,
    meta: null,
  },
  biohazard: {
    Icon: Biohazard,
    meta: null,
  },
  bluetooth: {
    Icon: Bluetooth,
    meta: null,
  },
  "box-open": {
    Icon: BoxOpen,
    meta: null,
  },
  "briefcase-medical": {
    Icon: BriefcaseMedical,
    meta: null,
  },
  building: {
    Icon: Building,
    meta: null,
  },
  "calendar-check": {
    Icon: CalendarCheck,
    meta: null,
  },
  "calendar-days": {
    Icon: CalendarDays,
    meta: null,
  },
  camera: {
    Icon: Camera,
    meta: null,
  },
  "car-medical": {
    Icon: CarMedical,
    meta: null,
  },
  "caret-down": {
    Icon: CaretDown,
    meta: null,
  },
  "caret-square-down": {
    Icon: CaretSquareDown,
    meta: null,
  },
  "caret-up": {
    Icon: CaretUp,
    meta: null,
  },
  "caret-up-down": {
    Icon: CaretUpDown,
    meta: null,
  },
  "chart-line": {
    Icon: ChartLine,
    meta: null,
  },
  check: {
    Icon: Check,
    meta: null,
  },
  "check-alt-circle": {
    Icon: CheckAltCircle,
    meta: null,
  },
  "check-square": {
    Icon: CheckSquare,
    meta: null,
  },
  "cintas-antiseptics-color": {
    Icon: CintasAntisepticsColor,
    meta: null,
  },
  "cintas-antiseptics-gray": {
    Icon: CintasAntisepticsGray,
    meta: null,
  },
  "cintas-bandages-color": {
    Icon: CintasBandagesColor,
    meta: null,
  },
  "cintas-bandages-gray": {
    Icon: CintasBandagesGray,
    meta: null,
  },
  "cintas-burns-color": {
    Icon: CintasBurnsColor,
    meta: null,
  },
  "cintas-burns-gray": {
    Icon: CintasBurnsGray,
    meta: null,
  },
  "cintas-device": {
    Icon: CintasDevice,
    meta: null,
  },
  "cintas-emergency-color": {
    Icon: CintasEmergencyColor,
    meta: null,
  },
  "cintas-emergency-gray": {
    Icon: CintasEmergencyGray,
    meta: null,
  },
  "cintas-eyes-color": {
    Icon: CintasEyesColor,
    meta: null,
  },
  "cintas-eyes-gray": {
    Icon: CintasEyesGray,
    meta: null,
  },
  "cintas-incidents": {
    Icon: CintasIncidents,
    meta: null,
  },
  "cintas-productivity-color": {
    Icon: CintasProductivityColor,
    meta: null,
  },
  "cintas-productivity-gray": {
    Icon: CintasProductivityGray,
    meta: null,
  },
  "cintas-store": {
    Icon: CintasStore,
    meta: null,
  },
  "cintas-tablets-color": {
    Icon: CintasTabletsColor,
    meta: null,
  },
  "cintas-tablets-gray": {
    Icon: CintasTabletsGray,
    meta: null,
  },
  "cintas-tools-color": {
    Icon: CintasToolsColor,
    meta: null,
  },
  "cintas-tools-gray": {
    Icon: CintasToolsGray,
    meta: null,
  },
  "circle-checked": {
    Icon: CircleChecked,
    meta: null,
  },
  "circle-outline": {
    Icon: CircleOutline,
    meta: null,
  },
  "circle-outline-with-dot": {
    Icon: CircleOutlineWithDot,
    meta: null,
  },
  "circle-solid": {
    Icon: CircleSolid,
    meta: null,
  },
  "clever-first-aid-logo": {
    Icon: CleverFirstAidLogo,
    meta: null,
  },
  "clever-first-aid-logo-padded": {
    Icon: CleverFirstAidLogoPadded,
    meta: null,
  },
  "clipboard-check": {
    Icon: ClipboardCheck,
    meta: null,
  },
  "clipboard-list": {
    Icon: ClipboardList,
    meta: null,
  },
  clock: {
    Icon: Clock,
    meta: null,
  },
  "cloud-download": {
    Icon: CloudDownload,
    meta: null,
  },
  "cloud-offline": {
    Icon: CloudOffline,
    meta: null,
  },
  "cloud-offline-solid": {
    Icon: CloudOfflineSolid,
    meta: null,
  },
  "cloud-upload": {
    Icon: CloudUpload,
    meta: null,
  },
  cogs: {
    Icon: Cogs,
    meta: null,
  },
  "comment-dots": {
    Icon: CommentDots,
    meta: null,
  },
  compress: {
    Icon: Compress,
    meta: null,
  },
  "compress-alt": {
    Icon: CompressAlt,
    meta: null,
  },
  copy: {
    Icon: Copy,
    meta: null,
  },
  cube: {
    Icon: Cube,
    meta: null,
  },
  cut: {
    Icon: Cut,
    meta: null,
  },
  "device-aed": {
    Icon: DeviceAed,
    meta: null,
  },
  "device-clever-shelf": {
    Icon: DeviceCleverShelf,
    meta: null,
  },
  "device-eye-wash": {
    Icon: DeviceEyeWash,
    meta: null,
  },
  "device-first-aid-cabinet": {
    Icon: DeviceFirstAidCabinet,
    meta: null,
  },
  edit: {
    Icon: Edit,
    meta: null,
  },
  "ellip-horizontal": {
    Icon: EllipHorizontal,
    meta: null,
  },
  envelope: {
    Icon: Envelope,
    meta: null,
  },
  exclamation: {
    Icon: Exclamation,
    meta: null,
  },
  "exclamation-alt-circle": {
    Icon: ExclamationAltCircle,
    meta: null,
  },
  "exclamation-triangle": {
    Icon: ExclamationTriangle,
    meta: null,
  },
  expand: {
    Icon: Expand,
    meta: null,
  },
  "expand-alt": {
    Icon: ExpandAlt,
    meta: null,
  },
  eye: {
    Icon: Eye,
    meta: null,
  },
  "eye-slash": {
    Icon: EyeSlash,
    meta: null,
  },
  filter: {
    Icon: Filter,
    meta: null,
  },
  "filter-alt": {
    Icon: FilterAlt,
    meta: null,
  },
  "first-aid-inverted": {
    Icon: FirstAidInverted,
    meta: null,
  },
  grid: {
    Icon: Grid,
    meta: null,
  },
  "hand-shake": {
    Icon: HandShake,
    meta: null,
  },
  heart: {
    Icon: Heart,
    meta: null,
  },
  "heart-outline": {
    Icon: HeartOutline,
    meta: null,
  },
  history: {
    Icon: History,
    meta: null,
  },
  home: {
    Icon: Home,
    meta: null,
  },
  "hourglass-end": {
    Icon: HourglassEnd,
    meta: null,
  },
  image: {
    Icon: Image,
    meta: null,
  },
  info: {
    Icon: Info,
    meta: null,
  },
  "info-circle": {
    Icon: InfoCircle,
    meta: null,
  },
  language: {
    Icon: Language,
    meta: null,
  },
  "layer-group": {
    Icon: LayerGroup,
    meta: null,
  },
  "level-up-in": {
    Icon: LevelUpIn,
    meta: null,
  },
  "level-up-left": {
    Icon: LevelUpLeft,
    meta: null,
  },
  "level-up-out": {
    Icon: LevelUpOut,
    meta: null,
  },
  "level-up-right": {
    Icon: LevelUpRight,
    meta: null,
  },
  link: {
    Icon: Link,
    meta: null,
  },
  list: {
    Icon: List,
    meta: null,
  },
  "location-arrow": {
    Icon: LocationArrow,
    meta: null,
  },
  "location-crosshairs": {
    Icon: LocationCrosshairs,
    meta: null,
  },
  lock: {
    Icon: Lock,
    meta: null,
  },
  "map-marked-alt": {
    Icon: MapMarkedAlt,
    meta: null,
  },
  "map-pin": {
    Icon: MapPin,
    meta: null,
  },
  minimise: {
    Icon: Minimise,
    meta: null,
  },
  minus: {
    Icon: Minus,
    meta: null,
  },
  mobile: {
    Icon: Mobile,
    meta: null,
  },
  "mobile-alt": {
    Icon: MobileAlt,
    meta: null,
  },
  "mobile-and-envelope": {
    Icon: MobileAndEnvelope,
    meta: null,
  },
  money: {
    Icon: Money,
    meta: null,
  },
  palette: {
    Icon: Palette,
    meta: null,
  },
  "pause-circle": {
    Icon: PauseCircle,
    meta: null,
  },
  "pencil-alt": {
    Icon: PencilAlt,
    meta: null,
  },
  "person-slipping": {
    Icon: PersonSlipping,
    meta: null,
  },
  phone: {
    Icon: Phone,
    meta: null,
  },
  plaster: {
    Icon: Plaster,
    meta: null,
  },
  plug: {
    Icon: Plug,
    meta: null,
  },
  plus: {
    Icon: Plus,
    meta: null,
  },
  "power-off": {
    Icon: PowerOff,
    meta: null,
  },
  "prox-logo": {
    Icon: ProxLogo,
    meta: null,
  },
  qr: {
    Icon: Qr,
    meta: null,
  },
  question: {
    Icon: Question,
    meta: null,
  },
  "question-circle": {
    Icon: QuestionCircle,
    meta: null,
  },
  "rotate-left": {
    Icon: RotateLeft,
    meta: null,
  },
  search: {
    Icon: Search,
    meta: null,
  },
  send: {
    Icon: Send,
    meta: null,
  },
  sensors: {
    Icon: Sensors,
    meta: null,
  },
  "shield-alt": {
    Icon: ShieldAlt,
    meta: null,
  },
  sidebar: {
    Icon: Sidebar,
    meta: null,
  },
  "sign-in": {
    Icon: SignIn,
    meta: null,
  },
  "sign-out": {
    Icon: SignOut,
    meta: null,
  },
  "smart-shelf": {
    Icon: SmartShelf,
    meta: null,
  },
  spinner: {
    Icon: Spinner,
    meta: { spin: true },
  },
  square: {
    Icon: Square,
    meta: null,
  },
  "square-checked": {
    Icon: SquareChecked,
    meta: null,
  },
  "square-o": {
    Icon: SquareO,
    meta: null,
  },
  star: {
    Icon: Star,
    meta: null,
  },
  "star-outline": {
    Icon: StarOutline,
    meta: null,
  },
  "tachometer-alt": {
    Icon: TachometerAlt,
    meta: null,
  },
  temperature: {
    Icon: Temperature,
    meta: null,
  },
  times: {
    Icon: Times,
    meta: null,
  },
  tint: {
    Icon: Tint,
    meta: null,
  },
  "toggle-off": {
    Icon: ToggleOff,
    meta: null,
  },
  "toggle-on": {
    Icon: ToggleOn,
    meta: null,
  },
  "trash-alt": {
    Icon: TrashAlt,
    meta: null,
  },
  truck: {
    Icon: Truck,
    meta: null,
  },
  user: {
    Icon: User,
    meta: null,
  },
  "user-friends": {
    Icon: UserFriends,
    meta: null,
  },
  "user-minus": {
    Icon: UserMinus,
    meta: null,
  },
  "user-plus": {
    Icon: UserPlus,
    meta: null,
  },
  users: {
    Icon: Users,
    meta: null,
  },
  "zoom-in": {
    Icon: ZoomIn,
    meta: null,
  },
  "zoom-out": {
    Icon: ZoomOut,
    meta: null,
  },
};

export default icons;
export type IIcon = keyof typeof icons;
