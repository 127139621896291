/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const ArrowUp = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M3.13476 13.5632L2.09414 12.528C1.65351 12.0897 1.65351 11.381 2.09414 10.9474L11.2019 1.8831C11.6426 1.44481 12.3551 1.44481 12.791 1.8831L21.9035 10.9474C22.3441 11.3857 22.3441 12.0944 21.9035 12.528L20.8629 13.5632C20.4176 14.0061 19.691 13.9968 19.2551 13.5445L13.8738 7.92596L13.8738 21.3266C13.8738 21.9467 13.3723 22.4456 12.7488 22.4456L11.2488 22.4456C10.6254 22.4456 10.1238 21.9467 10.1238 21.3266L10.1238 7.92596L4.74257 13.5445C4.30664 14.0015 3.58007 14.0108 3.13476 13.5632Z" />
  </svg>
);

export default ArrowUp;
