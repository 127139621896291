/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const BoxOpen = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M15.9637 12C15.33 12 14.7337 11.6625 14.4112 11.1225L12 7.12503L9.59249 11.1225C9.26624 11.6663 8.66999 12.0038 8.03624 12.0038C7.86749 12.0038 7.69874 11.9813 7.53749 11.9325L2.4 10.4625V17.1375C2.4 17.6888 2.775 18.1688 3.3075 18.3L11.415 20.3288C11.7975 20.4226 12.1987 20.4226 12.5775 20.3288L20.6925 18.3C21.225 18.165 21.6 17.685 21.6 17.1375V10.4625L16.4625 11.9288C16.3012 11.9775 16.1325 12 15.9637 12ZM23.9362 7.79253L22.005 3.93752C21.8887 3.70502 21.6375 3.57002 21.3787 3.60377L12 4.80002L15.4387 10.5038C15.5812 10.74 15.8662 10.8525 16.1325 10.7775L23.5537 8.65878C23.925 8.55003 24.105 8.13753 23.9362 7.79253ZM1.995 3.93752L0.0637502 7.79253C-0.10875 8.13753 0.0750002 8.55003 0.4425 8.65503L7.86374 10.7738C8.12999 10.8488 8.41499 10.7363 8.55749 10.5L12 4.80002L2.6175 3.60377C2.35875 3.57377 2.11125 3.70502 1.995 3.93752Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default BoxOpen;
