/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const PencilAlt = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M23.339 6.66094L21.1781 8.82187C20.9578 9.04219 20.6016 9.04219 20.3812 8.82187L15.1781 3.61875C14.9578 3.39843 14.9578 3.04218 15.1781 2.82187L17.3391 0.660932C18.2156 -0.215631 19.6406 -0.215631 20.5219 0.660932L23.339 3.47812C24.2203 4.35468 24.2203 5.77969 23.339 6.66094ZM13.3219 4.67812L1.0125 16.9875L0.0187494 22.6828C-0.117188 23.4516 0.553124 24.1172 1.32187 23.9859L7.01718 22.9875L19.3266 10.6781C19.5469 10.4578 19.5469 10.1016 19.3266 9.88125L14.1234 4.67812C13.8984 4.45781 13.5422 4.45781 13.3219 4.67812ZM5.81718 15.9328C5.55937 15.675 5.55937 15.2625 5.81718 15.0047L13.0359 7.78594C13.2937 7.52812 13.7062 7.52812 13.9641 7.78594C14.2219 8.04375 14.2219 8.45625 13.9641 8.71406L6.74531 15.9328C6.4875 16.1906 6.075 16.1906 5.81718 15.9328ZM4.125 19.875H6.375V21.5766L3.35156 22.1063L1.89375 20.6484L2.42344 17.625H4.125V19.875Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default PencilAlt;
