/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Minimise = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M1.5 19.5C0.670312 19.5 0 20.1703 0 21C0 21.8297 0.670312 22.5 1.5 22.5H22.5C23.3297 22.5 24 21.8297 24 21C24 20.1703 23.3297 19.5 22.5 19.5H1.5Z" />
  </svg>
);

export default Minimise;
