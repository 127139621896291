/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const SignOut = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M23.2969 12.7968L15.4219 20.6718C14.7188 21.375 13.5 20.8828 13.5 19.875V15.375H7.125C6.50156 15.375 6 14.8734 6 14.25V9.74996C6 9.12652 6.50156 8.62496 7.125 8.62496H13.5V4.12496C13.5 3.12183 14.7141 2.62496 15.4219 3.32808L23.2969 11.2031C23.7328 11.6437 23.7328 12.3562 23.2969 12.7968ZM9 20.4375V18.5625C9 18.2531 8.74687 18 8.4375 18H4.5C3.67031 18 3 17.3296 3 16.5V7.49996C3 6.67027 3.67031 5.99996 4.5 5.99996H8.4375C8.74687 5.99996 9 5.74683 9 5.43746V3.56246C9 3.25308 8.74687 2.99996 8.4375 2.99996H4.5C2.01562 2.99996 0 5.01558 0 7.49996V16.5C0 18.9843 2.01562 21 4.5 21H8.4375C8.74687 21 9 20.7468 9 20.4375Z" />
  </svg>
);

export default SignOut;
