/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const BagMedical = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M6 8C2.68629 8 0 10.6863 0 14V20C0 21.1046 0.89543 22 2 22H22C23.1046 22 24 21.1046 24 20V14C24 10.6863 21.3137 8 18 8H6ZM16 16C16 16.1833 15.85 16.3333 15.6667 16.3333H13.3333V18.6667C13.3333 18.85 13.1833 19 13 19H11C10.8167 19 10.6667 18.85 10.6667 18.6667V16.3333H8.33333C8.15 16.3333 8 16.1833 8 16V14C8 13.8167 8.15 13.6667 8.33333 13.6667H10.6667V11.3333C10.6667 11.15 10.8167 11 11 11H13C13.1833 11 13.3333 11.15 13.3333 11.3333V13.6667H15.6667C15.85 13.6667 16 13.8167 16 14V16Z"
    />
    <path d="M18 8C18 7.21207 17.8448 6.43185 17.5433 5.7039C17.2417 4.97595 16.7998 4.31451 16.2426 3.75736C15.6855 3.20021 15.0241 2.75825 14.2961 2.45672C13.5681 2.15519 12.7879 2 12 2C11.2121 2 10.4319 2.15519 9.7039 2.45672C8.97595 2.75825 8.31451 3.20021 7.75736 3.75736C7.20021 4.31451 6.75825 4.97595 6.45672 5.7039C6.15519 6.43185 6 7.21207 6 8H8.37696C8.37696 7.52422 8.47068 7.05309 8.65275 6.61352C8.83482 6.17396 9.1017 5.77456 9.43813 5.43813C9.77456 5.1017 10.174 4.83482 10.6135 4.65275C11.0531 4.47068 11.5242 4.37696 12 4.37696C12.4758 4.37696 12.9469 4.47068 13.3865 4.65275C13.826 4.83482 14.2254 5.1017 14.5619 5.43813C14.8983 5.77456 15.1652 6.17396 15.3472 6.61352C15.5293 7.05309 15.623 7.52422 15.623 8H18Z" />
  </svg>
);

export default BagMedical;
