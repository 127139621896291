/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const PauseCircle = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 18.4219 5.57812 23.625 12 23.625C18.4219 23.625 23.625 18.4219 23.625 12C23.625 5.57812 18.4219 0.375 12 0.375ZM11.25 15.75C11.25 16.1625 10.9125 16.5 10.5 16.5H8.25C7.8375 16.5 7.5 16.1625 7.5 15.75V8.25C7.5 7.8375 7.8375 7.5 8.25 7.5H10.5C10.9125 7.5 11.25 7.8375 11.25 8.25V15.75ZM16.5 15.75C16.5 16.1625 16.1625 16.5 15.75 16.5H13.5C13.0875 16.5 12.75 16.1625 12.75 15.75V8.25C12.75 7.8375 13.0875 7.5 13.5 7.5H15.75C16.1625 7.5 16.5 7.8375 16.5 8.25V15.75Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default PauseCircle;
