/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Bluetooth = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M12.9929 12.1571L16.7837 8.1207L10.8094 2V10.0598L7.28529 6.69605L6 7.92281L10.4224 12.1571L6 16.3915L7.28529 17.6182L10.8094 14.2545L10.9205 22L17 16.1936L12.9929 12.1571ZM14.6651 8.13387L12.6198 10.0861L12.606 6.16836L14.6651 8.13387ZM12.6198 14.2281L14.6651 16.1804L12.606 18.1459L12.6198 14.2281Z" />
  </svg>
);

export default Bluetooth;
