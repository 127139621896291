/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const LevelUpOut = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M18.3906 6.30213L23.6408 11.1755C24.1196 11.6201 24.1203 12.3791 23.6408 12.8243L18.3906 17.6981C17.6735 18.3641 16.5001 17.8564 16.5001 16.8738V13.8749L3.75012 13.8749L3.75012 17.8113C3.75013 17.8852 3.73558 17.9583 3.70731 18.0266C3.67904 18.0948 3.6376 18.1568 3.58536 18.209L0.960356 20.834C0.606028 21.1884 0.000122048 20.9374 0.00012207 20.4363L0.000122472 11.2499C0.000122499 10.6286 0.503794 10.1249 1.12512 10.1249L16.5001 10.1249V7.12652C16.5001 6.14787 17.6705 5.63351 18.3906 6.30213Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" transform="translate(24) rotate(90)" />
      </clipPath>
    </defs>
  </svg>
);

export default LevelUpOut;
