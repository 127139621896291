/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const DeviceCleverShelf = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <rect x="17.5" width="3" height="24" rx="1.5" />
    <rect x="3.5" width="3" height="24" rx="1.5" />
    <rect x="6" y="20" width="12" height="3" />
    <rect x="8.5" y="11" width="7" height="7" rx="1" />
    <rect x="8.5" y="2" width="7" height="7" rx="1" />
  </svg>
);

export default DeviceCleverShelf;
