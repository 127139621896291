/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const TachometerAlt = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M12 3C5.3725 3 0 8.3725 0 15C0 17.2 0.59375 19.2608 1.6275 21.0333C1.86125 21.4342 2.30667 21.6667 2.77083 21.6667H21.2292C21.6933 21.6667 22.1388 21.4342 22.3725 21.0333C23.4062 19.2608 24 17.2 24 15C24 8.3725 18.6275 3 12 3ZM12 5.66667C12.6129 5.66667 13.1075 6.08875 13.2633 6.65208C13.2171 6.74625 13.1533 6.82833 13.1196 6.93L12.7354 8.08292C12.5217 8.22833 12.2783 8.33333 12.0004 8.33333C11.2642 8.33333 10.6671 7.73625 10.6671 7C10.6671 6.26375 11.2638 5.66667 12 5.66667ZM4 17.6667C3.26375 17.6667 2.66667 17.0696 2.66667 16.3333C2.66667 15.5971 3.26375 15 4 15C4.73625 15 5.33333 15.5971 5.33333 16.3333C5.33333 17.0696 4.73625 17.6667 4 17.6667ZM6 11C5.26375 11 4.66667 10.4029 4.66667 9.66667C4.66667 8.93042 5.26375 8.33333 6 8.33333C6.73625 8.33333 7.33333 8.93042 7.33333 9.66667C7.33333 10.4029 6.73625 11 6 11ZM16.2821 7.98292L13.7267 15.6496C14.2971 16.1387 14.6667 16.8558 14.6667 17.6667C14.6667 18.155 14.5258 18.6062 14.2967 19H9.70333C9.47417 18.6062 9.33333 18.155 9.33333 17.6667C9.33333 16.2525 10.4375 15.1071 11.8292 15.0171L14.385 7.35C14.5588 6.82667 15.1238 6.53958 15.65 6.71792C16.1737 6.8925 16.4562 7.45917 16.2821 7.98292ZM16.8929 10.3662L17.5396 8.42667C17.6842 8.37292 17.8367 8.33375 18 8.33375C18.7362 8.33375 19.3333 8.93083 19.3333 9.66708C19.3333 10.4033 18.7362 11.0004 18 11.0004C17.5258 11 17.1296 10.7387 16.8929 10.3662ZM20 17.6667C19.2638 17.6667 18.6667 17.0696 18.6667 16.3333C18.6667 15.5971 19.2638 15 20 15C20.7362 15 21.3333 15.5971 21.3333 16.3333C21.3333 17.0696 20.7362 17.6667 20 17.6667Z" />
  </svg>
);

export default TachometerAlt;
