/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const ArrowUpRightFromSquare = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M13.5 3C13.5 2.17172 14.1703 1.5 15 1.5H20.9578C21.2016 1.5 21.3984 1.54031 21.5719 1.61391C21.7078 1.68656 21.9141 1.79391 22.0594 1.93594C22.0594 1.93828 22.0594 1.94062 22.0641 1.9425C22.3547 2.23406 22.4578 2.61469 22.5 2.99531C22.5 2.99719 22.5 2.99859 22.5 3V9C22.5 9.82969 21.8297 10.5 21 10.5C20.1703 10.5 19.5 9.82969 19.5 9V6.62344L11.5594 14.5594C10.9734 15.1453 10.0266 15.1453 9.44062 14.5594C8.85469 13.9734 8.85469 13.0266 9.44062 12.4406L17.3766 4.5H15C14.1703 4.5 13.5 3.82828 13.5 3ZM1.5 6C1.5 4.34297 2.84297 3 4.5 3H9C9.82969 3 10.5 3.67172 10.5 4.5C10.5 5.32969 9.82969 6 9 6H4.5V19.5H18V15C18 14.1703 18.6703 13.5 19.5 13.5C20.3297 13.5 21 14.1703 21 15V19.5C21 21.1547 19.6547 22.5 18 22.5H4.5C2.84297 22.5 1.5 21.1547 1.5 19.5V6Z" />
  </svg>
);

export default ArrowUpRightFromSquare;
