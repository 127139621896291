/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Palette = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M24 12V12.1266C23.9813 13.8375 22.425 15 20.7141 15H16.125C14.8828 15 13.875 16.0078 13.875 17.25C13.875 17.4094 13.8938 17.5641 13.9219 17.7141C14.0203 18.1922 14.2266 18.6516 14.4281 19.1156C14.7141 19.7625 14.9953 20.4047 14.9953 21.0844C14.9953 22.575 13.9828 23.9297 12.4922 23.9906C12.3281 23.9953 12.1641 24 11.9953 24C5.37187 24 0 18.6281 0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12ZM6 13.5C6 13.1022 5.84196 12.7206 5.56066 12.4393C5.27936 12.158 4.89782 12 4.5 12C4.10218 12 3.72064 12.158 3.43934 12.4393C3.15804 12.7206 3 13.1022 3 13.5C3 13.8978 3.15804 14.2794 3.43934 14.5607C3.72064 14.842 4.10218 15 4.5 15C4.89782 15 5.27936 14.842 5.56066 14.5607C5.84196 14.2794 6 13.8978 6 13.5ZM6 9C6.39782 9 6.77936 8.84196 7.06066 8.56066C7.34196 8.27936 7.5 7.89782 7.5 7.5C7.5 7.10218 7.34196 6.72064 7.06066 6.43934C6.77936 6.15804 6.39782 6 6 6C5.60218 6 5.22064 6.15804 4.93934 6.43934C4.65804 6.72064 4.5 7.10218 4.5 7.5C4.5 7.89782 4.65804 8.27936 4.93934 8.56066C5.22064 8.84196 5.60218 9 6 9ZM13.5 4.5C13.5 4.10218 13.342 3.72064 13.0607 3.43934C12.7794 3.15804 12.3978 3 12 3C11.6022 3 11.2206 3.15804 10.9393 3.43934C10.658 3.72064 10.5 4.10218 10.5 4.5C10.5 4.89782 10.658 5.27936 10.9393 5.56066C11.2206 5.84196 11.6022 6 12 6C12.3978 6 12.7794 5.84196 13.0607 5.56066C13.342 5.27936 13.5 4.89782 13.5 4.5ZM18 9C18.3978 9 18.7794 8.84196 19.0607 8.56066C19.342 8.27936 19.5 7.89782 19.5 7.5C19.5 7.10218 19.342 6.72064 19.0607 6.43934C18.7794 6.15804 18.3978 6 18 6C17.6022 6 17.2206 6.15804 16.9393 6.43934C16.658 6.72064 16.5 7.10218 16.5 7.5C16.5 7.89782 16.658 8.27936 16.9393 8.56066C17.2206 8.84196 17.6022 9 18 9Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default Palette;
