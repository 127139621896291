/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const SquareChecked = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M20.25 1.5H3.75C2.50734 1.5 1.5 2.50734 1.5 3.75V20.25C1.5 21.4927 2.50734 22.5 3.75 22.5H20.25C21.4927 22.5 22.5 21.4927 22.5 20.25V3.75C22.5 2.50734 21.4927 1.5 20.25 1.5ZM20.25 20.25H3.75V3.75H20.25V20.25Z" />
    <path d="M10.4789 16.9444L18.569 8.91922C18.7895 8.70045 18.7909 8.3443 18.5722 8.1237L17.5158 7.0588C17.297 6.83825 16.9409 6.83684 16.7203 7.05561L10.0944 13.6282L7.2917 10.8027C7.07293 10.5822 6.71677 10.5807 6.49623 10.7995L5.43127 11.8559C5.21068 12.0747 5.20927 12.4309 5.42804 12.6514L9.6834 16.9412C9.90216 17.1618 10.2583 17.1632 10.4789 16.9444Z" />
  </svg>
);

export default SquareChecked;
