/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const DeviceAed = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M12.5 4.49994C14.9852 2.01467 19.0147 2.01467 21.4999 4.49994C23.9852 6.98523 23.9852 11.0147 21.4999 13.4999L13.4141 21.5857C12.6331 22.3667 11.3667 22.3667 10.5857 21.5857L2.49994 13.4999C0.0146664 11.0147 0.0146681 6.98524 2.49994 4.49996C4.98523 2.01468 9.01467 2.01469 11.4999 4.49998L11.9999 4.99998L12.5 4.49994ZM10.0909 8L9 12.4444H11.1818L10.6364 14.6667H12.2727L11.7273 18L15 12.4444H13.3636L13.9091 10.2222H11.7273L12.2727 8H10.0909Z"
    />
  </svg>
);

export default DeviceAed;
