/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const CompressAlt = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M1.71966 20.0303L6.375 15.375L4.83178 13.9205C4.12303 13.2118 4.62497 12 5.62725 12H10.8773C11.4986 12 12 12.5037 12 13.125V18.375C12 19.3773 10.7905 19.8792 10.0818 19.1705L8.625 17.625L3.96966 22.2803C3.67678 22.5732 3.20189 22.5732 2.90902 22.2803L1.71966 21.091C1.42678 20.7981 1.42678 20.3232 1.71966 20.0303ZM22.2803 3.96966L17.625 8.625L19.1682 10.0795C19.877 10.7882 19.375 12 18.3727 12H13.1227C12.5014 12 12 11.4963 12 10.875V5.625C12 4.62272 13.2095 4.12078 13.9182 4.82948L15.375 6.375L20.0303 1.71966C20.3232 1.42678 20.7981 1.42678 21.091 1.71966L22.2803 2.90902C22.5732 3.20189 22.5732 3.67678 22.2803 3.96966Z" />
  </svg>
);

export default CompressAlt;
