/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const UserPlus = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M23.4 10.2H21V7.79999C21 7.46999 20.73 7.19999 20.4 7.19999H19.2C18.87 7.19999 18.6 7.46999 18.6 7.79999V10.2H16.2C15.87 10.2 15.6 10.47 15.6 10.8V12C15.6 12.33 15.87 12.6 16.2 12.6H18.6V15C18.6 15.33 18.87 15.6 19.2 15.6H20.4C20.73 15.6 21 15.33 21 15V12.6H23.4C23.73 12.6 24 12.33 24 12V10.8C24 10.47 23.73 10.2 23.4 10.2ZM8.4 12C11.0513 12 13.2 9.85124 13.2 7.19999C13.2 4.54874 11.0513 2.39999 8.4 2.39999C5.74875 2.39999 3.6 4.54874 3.6 7.19999C3.6 9.85124 5.74875 12 8.4 12ZM11.76 13.2H11.1337C10.3013 13.5825 9.375 13.8 8.4 13.8C7.425 13.8 6.5025 13.5825 5.66625 13.2H5.04C2.2575 13.2 0 15.4575 0 18.24V19.8C0 20.7937 0.80625 21.6 1.8 21.6H15C15.9938 21.6 16.8 20.7937 16.8 19.8V18.24C16.8 15.4575 14.5425 13.2 11.76 13.2Z" />
  </svg>
);

export default UserPlus;
