/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Tint = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path d="M13.3697 1.03512C12.9975 -0.313943 11.0522 -0.376756 10.6303 1.03512C8.43797 8.43012 3.75 10.4397 3.75 15.6517C3.75 20.2661 7.44 23.9997 12 23.9997C16.56 23.9997 20.25 20.2661 20.25 15.6517C20.25 10.4134 15.5723 8.46387 13.3697 1.03512ZM12 20.9997C9.10547 20.9997 6.75 18.6442 6.75 15.7497C6.75 15.3353 7.08562 14.9997 7.5 14.9997C7.91437 14.9997 8.25 15.3353 8.25 15.7497C8.25 17.8173 9.93234 19.4997 12 19.4997C12.4144 19.4997 12.75 19.8353 12.75 20.2497C12.75 20.664 12.4144 20.9997 12 20.9997Z" />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default Tint;
