/**
 * This file is generated by a tool, do not edit it.
 * Figma Icon Exporter 3.1.2
 */

const Send = () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path
        fill-rule="evenodd"
        d="M3.02991 0.650488L22.6811 9.92218C24.4396 10.6491 24.4396 13.3502 22.6811 14.0777L3.02991 23.3494C0.754284 24.1806 -1.00361 21.3756 0.651125 19.7136L5.23347 13.5H15V10.5H5.23353L0.651125 4.28634C-1.00361 2.62432 0.754608 -0.180359 3.02991 0.650488Z"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default Send;
